import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  // Box,
  InputAdornment, SvgIcon, makeStyles
} from '@material-ui/core';
import { Navigation, Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { POS_Session_Create, POS_Session_Get } from 'src/store/actions/posActions';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formFilterControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const Toolbar = ({ className, filters, setfilters, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const userDetailId = useSelector(state => state.auth.user.User_Details_id);

  const [sessionGetCheck, setsessionGetCheck] = useState(false);
  const [cashInHandGet, setCashInHandGet] = useState(0);
  const [upiAmountGet, setupiAmountGet] = useState(0);
  const [cardAmountGet, setcardAmountGet] = useState(0);
  const [sessionIdGet, setSessionIdGet] = useState('');

  useEffect(() => {
    sessionGet();
  }, []);

  const sessionGet = async () => {
    const formData = {
      Admin_Details_Id: userDetailId
    }
    const result = await POS_Session_Get(formData);
    setsessionGetCheck(result?.data[0]?.Is_Session_Active === 1)
    setCashInHandGet(result?.data[0]?.Opening_Cash)
    setSessionIdGet(result?.data[0]?.Pos_Session_Id)
    setupiAmountGet(result?.data[0]?.Upi_Payment)
    setcardAmountGet(result?.data[0]?.Card_Payment)
  }


  const handleChange = event => {
    setfilters({
      search: filters?.search,
      search_by_filter: event.target.value
    })
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickToSession = () => {
    navigate('/app/product-management/product-pos', { state: { cashInHand: cashInHandGet, upiAmount: upiAmountGet, cardAmount: cardAmountGet, sessionID: sessionIdGet } });
  };

  const [cashInHand, setCashInHand] = useState('');
  const [drawerNo, setDrawerNo] = useState('');
  const [error, setError] = useState({ cashInHand: false, drawerNo: false });

  const handleCashInHandChange = (event) => {
    setCashInHand(event.target.value);
    setError((prevError) => ({ ...prevError, cashInHand: false }));
  };

  const handleDrawerNoChange = (event) => {
    setDrawerNo(event.target.value);
    setError((prevError) => ({ ...prevError, drawerNo: false }));
  };


  const handleSubmit = async () => {
    // Convert input values to numbers
    const cashInHandNumber = Number(cashInHand);
    const drawerNoNumber = Number(drawerNo);

    // Validate input values
    const isCashInHandValid = !isNaN(cashInHandNumber) && cashInHandNumber > 0;
    const isDrawerNoValid = !isNaN(drawerNoNumber) && drawerNoNumber > 0;

    // Set error state and exit if validation fails
    if (!isCashInHandValid || !isDrawerNoValid) {
      setError({
        cashInHand: !isCashInHandValid,
        drawerNo: !isDrawerNoValid,
      });
      return;
    }

    const formData = {
      "Admin_Details_Id": userDetailId,
      "Created_By": loginEmail,
      "Billing_Counter_Number": drawerNoNumber,
      "Opening_Cash": cashInHandNumber
    };

    const result = await dispatch(POS_Session_Create(formData));
    // Check if the dispatch was successful
    if (result.status) {
      // Only navigate if the dispatch is successful
      const sessionData = {
        "Pos_Session_Id": result.data.POS_Session_Id
      }
      const resul = await POS_Session_Get(sessionData);
      if (resul.status)
        navigate('/app/product-management/product-pos', { state: { cashInHand: resul?.data[0].Opening_Cash, sessionID: result.data.POS_Session_Id } });
    } else {
      toast('Failed to create session. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      });
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexWrap="wrap" mb={2}>
          <FormControl variant="outlined" className={classes.formFilterControl}>
            <Select
              value={filters.search_by_filter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Order_Id">Order ID</MenuItem>
              {/* <MenuItem value="User_Email_Id">User Email ID</MenuItem> */}
              <MenuItem value="Invoice_No">Invoice No</MenuItem>
              <MenuItem value="Full_Name">Customer Name</MenuItem>
              {/* <MenuItem value="Order_Status">Order Status</MenuItem> */}
              <MenuItem value="Mobile">Customer Mobile No</MenuItem>
              {/* <MenuItem value="Total_Amount">Total Amount</MenuItem> */}
              <MenuItem value="Order_Amount">Order Amount</MenuItem>
              <MenuItem value="Payment_Type">Payment Type</MenuItem>
            </Select>
          </FormControl>
          <Box width={350} sx={{ marginTop: '10px' }}>
            <TextField
              onChange={(event) => {
                setfilters({
                  search: event.target.value,
                  search_by_filter: filters.search_by_filter
                })
              }}
              className={classes.formControl}
              value={filters?.search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search Orders"
              variant="outlined"
            />
          </Box>
        </Box>
        {!sessionGetCheck ?
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickOpen}
            style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
          >
            <span style={{ color: "white" }}>
              + CREATE NEW
            </span>
          </Button>
          :
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleClickToSession()}
            style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
          >
            <span style={{ color: "white" }}>
              Go To POS
            </span>
          </Button>}
      </Box>
      {/* to create new session */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle fontSize={'15px'}>
          Cash in hand details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent >
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              autoFocus
              margin="dense"
              label="Cash in hand"
              type={'number'}
              required
              value={cashInHand}
              onChange={handleCashInHandChange}
              error={error.cashInHand} // Show error state
              helperText={error.cashInHand ? 'Cash in hand is required' : ''}
              InputProps={{
                sx: {
                  '& input::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                  },
                  '& input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                  },
                  '& input[type="number"]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              sx={{ width: '175px' }}
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              label="Drawer No"
              type={'number'}
              required
              value={drawerNo}
              onChange={handleDrawerNoChange}
              error={error.drawerNo} // Show error state
              helperText={error.drawerNo ? 'Drawer No is required' : ''}
              InputProps={{
                sx: {
                  '& input::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                  },
                  '& input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                  },
                  '& input[type="number"]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              sx={{ width: '175px' }}
              variant="outlined"
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ alignSelf: 'center' }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ backgroundColor: '#00796b', width: '150px', alignSelf: 'center' }}
          >
            START SELLING
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
};

export default Toolbar;