import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    makeStyles
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import CorpPagination from 'src/components/pagination/corppagination';
import isEmpty from 'src/store/validations/is-empty';

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2)
    },
    headerColumn: {
        fontSize: "12px",
        marginRight: "-16px",
        fontWeight: "bold",
    }
}));

const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'Unique id' },
    { id: 'Activity_Name', numeric: false, disablePadding: true, label: 'Activity name' },
    { id: 'Activity_Type', numeric: false, disablePadding: true, label: 'Activity type' },
    { id: 'Category', numeric: false, disablePadding: true, label: 'Category' },
    { id: 'Created_On', numeric: false, disablePadding: true, label: 'Created on' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
];

const Results = ({
    className,
    allActivityManagement,
    deleteActivityManagementHandler,
    pagination,
    setPagination,
    handleLimitChange,
    handlePageChange,
    order,
    orderBy,
    createSortHandler,
    allActivityManagementNoFilter,
    RolePermissions,
    roleUserType,
    ...rest
}) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const editActivityManagementHandler = activitymanagementData => e => {
        navigate('/app/update-corpemp-offline-activity', { state: activitymanagementData });
    };

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
                <Box minWidth={1400}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel className={classes.headerColumn}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headerColumn} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-55%' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isEmpty(allActivityManagement) &&
                                (allActivityManagement).map(activitymanagement => (
                                    <TableRow
                                        hover
                                        key={activitymanagement.id}
                                    >
                                        <TableCell>{activitymanagement.id}</TableCell>
                                        <TableCell>{activitymanagement.Activity_Name}</TableCell>
                                        <TableCell>{activitymanagement.Activity_Type}</TableCell>
                                        <TableCell>{activitymanagement.Category}</TableCell>
                                        <TableCell>{activitymanagement.Created_On}</TableCell>
                                        <TableCell>
                                            <span
                                                style={{
                                                    color: activitymanagement.Status === "Active" ? "green" : "red",
                                                    border: `1px solid ${activitymanagement.Status === "Active" ? "green" : "red"}`,
                                                    borderRadius: "50px",
                                                    padding: "8px 8px",
                                                    display: "inline-block",
                                                }}
                                            >
                                                {activitymanagement.Status}
                                            </span>
                                        </TableCell>
                                        {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                                            (RolePermissions?.CorpEmp_Activity_Management_Write?.Value === "CorpEmp_Activity_Management_Write" && RolePermissions?.CorpEmp_Activity_Management_Write?.Is_Write === 1) ?
                                            <TableCell >
                                                <Button onClick={editActivityManagementHandler(activitymanagement)} >
                                                    <CreateIcon style={{ color: '#9EA0A5' }} />
                                                </Button>
                                                <Button>
                                                    <DeleteIcon onClick={deleteActivityManagementHandler(activitymanagement)}
                                                        style={{ color: '#9EA0A5' }}
                                                    />
                                                </Button>
                                            </TableCell>
                                            : null}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
                {isEmpty(allActivityManagement) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', fontSize: 20 }}>No records found!</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }} >
                            <img height="400" src="/static/nodata.gif" alt="" />
                        </div>
                    </>
                }
            </PerfectScrollbar>
            <CorpPagination
                pagination={pagination}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
            />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    allActivityManagement: PropTypes.array.isRequired
};

export default Results;