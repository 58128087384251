import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationAllCorpEmpHappinessSurveyGet } from 'src/store/actions/node-actions/nodepagination';
import { deleteCorpEmpHappinessSurveyAction } from 'src/store/actions/corpemphappinesssurveyAction';
import ConfirmDialog from 'src/components/dialogs/confirmDialog';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const HappinessSurveyListView = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [happinessSurvey, setHappinessSurvey] = useState([]);

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [filters, setFilters] = React.useState({
        "Search": '',
        "Search_By_Filter": 'All'
    });

    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ page: 1, limit: 10, sortBy: "createdAt:desc" })

    const handleLimitChange = event => {
        setPagination({ ...pagination, limit: +event.target.value, page: 1, sortBy: "createdAt:desc" });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1, sortBy: "createdAt:desc" });
    };

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setHappinessSurvey(data);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ page: 1, limit: 10, sortBy: "createdAt:desc" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            happinessSurveyCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction()
        }
    }, [filters]);

    const searchFunction = () => {
        const searchpagination = {
            page: 1, limit: 10, sortBy: "createdAt:desc"
        }
        dispatch(paginationAllCorpEmpHappinessSurveyGet(filters, searchpagination, callBackPagination))
    }

    const happinessSurveyCall = () => {
        dispatch(paginationAllCorpEmpHappinessSurveyGet(filters, pagination, callBackPagination))
    };

    useEffect(() => {
        dispatch(getRolePermissions(Login_Details));
        dispatch(paginationAllCorpEmpHappinessSurveyGet(filters, pagination, callBackPagination))
    }, []);

    // confirm dailogbox
    const [copen, setCopen] = useState(false);
    const [ctitle, setCtitle] = useState('');
    const [cmessage, setCmessage] = useState('');
    const [happinesssurveyIdle, setHappinessSurveyIdle] = useState('');

    const chandleClose = (value) => {
        setCopen(false);
        if (value) {
            const deleteData = {
                Survey_Id: happinesssurveyIdle?.Survey_Id ? [happinesssurveyIdle?.Survey_Id] : happinesssurveyIdle
            };
            dispatch(deleteCorpEmpHappinessSurveyAction(deleteData, filters, { page: 1, limit: 10, sortBy: "createdAt:desc" }, callBackPagination));
        }
    };

    //Delete handler
    const deleteHappinessSurveyHandler = contentmanagementData => async e => {
        setCopen(true);
        setCtitle("Alert!");
        setCmessage("Are you sure to delete?");
        setHappinessSurveyIdle(contentmanagementData);
    };

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ page: 1, limit: pagination?.limit, sortBy: order === 'asc' ? "createdAt:asc" : "createdAt:desc" })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/corporate-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Survey Management
                    </Typography>
                </Box>
                <Toolbar filters={filters} setfilters={setFilters} roleUserType={roleUserType} />
                <Box mt={3}>
                    <Results
                        allHappinessSurvey={happinessSurvey}
                        allHappinessSurveyNoFilter={happinessSurvey}
                        deleteHappinessSurveyHandler={deleteHappinessSurveyHandler}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        pagination={pagination}
                        setPagination={setPagination}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
            <ConfirmDialog inputMessage={cmessage} titleMsg={ctitle} open={copen} onClose={chandleClose} />
        </Page>
    );
};

export default HappinessSurveyListView;