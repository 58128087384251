import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { corporateDateandCount } from 'src/store/actions/corporatedynamicActions';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'src/store/validations/is-empty';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    cursor: 'pointer',
    width: 58,
    height: 58
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = ({ className, product, rolePermission, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let dynamicvalue = useSelector((state) => state.corporatedateandcountReducer.corporatedateandcounts.all);
  const Login_Details = useSelector(state => state.auth.user);

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  useEffect(() => {
    if (isEmpty(dynamicvalue)) {
      dispatch(getRolePermissions(Login_Details));
      dispatch(corporateDateandCount());
    } else {
      setSeconds(5);
      if (product.title === "Pending Corporate Users") {
        product.hide = rolePermission.Corporate_Pending_Users_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Approved Corporate Users") {
        product.hide = rolePermission.Corporate_Approved_Users_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Rejected Corporate Users") {
        product.hide = rolePermission.Corporate_Rejected_Users_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate Coupon Management") {
        product.hide = rolePermission.Corporate_Coupon_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate FAQs") {
        product.hide = rolePermission.Corporate_FAQ_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_FAQ?.Corp_FAQ_Count;
        // product.createdAt = dynamicvalue?.Corp_FAQ?.Corp_FAQ_Date;
      }
      else if (product.title === "Corporate Content Management") {
        product.hide = rolePermission.Corporate_Content_Management_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate Ticket Management") {
        product.hide = rolePermission.Corporate_Ticket_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate News and Events") {
        product.hide = rolePermission.Corporate_NE_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate Order Management") {
        product.hide = rolePermission.Corporate_Order_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate Award management") {
        product.hide = rolePermission.Corporate_Award_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate Popup management") {
        product.hide = rolePermission.Corporate_Popup_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate Survey Management") {
        product.hide = rolePermission.CorpEmp_Happiness_Survey_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
      else if (product.title === "Corporate Activity Management") {
        product.hide = rolePermission.CorpEmp_Activity_Hide?.Is_Hide;
        // product.total = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Count;
        // product.createdAt = dynamicvalue?.Corp_Content_Management?.Corp_Content_Management_Date;
      }
    }
  }, [dynamicvalue]);

  return (
    <>
      {product.hide !== 1 &&
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Box display="flex" justifyContent="center" mb={3}>
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                alt="Product"
                src={product.media}
                variant="square"
                to={product.link}
              />
            </Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {product.title}
            </Typography>
            <Typography style={{ paddingTop: "5px" }} align="justify" color="textPrimary" variant="body1">
              {product.description}
            </Typography>
          </CardContent>
          <Box flexGrow={1} />
          {/* <Divider />
          <Box p={2}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid className={classes.statsItem} item>
                <AccessTimeIcon className={classes.statsIcon} color="action" />
                <Typography color="textSecondary" display="inline" variant="body2">
                  {product.createdAt}
                </Typography>
              </Grid>
              <Grid className={classes.statsItem} item>
                <Typography color="textSecondary" display="inline" variant="body2">
                  {product.total}
                </Typography>
              </Grid>
            </Grid>
          </Box> */}
        </Card>
      }
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;