import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { CircularProgress } from '@mui/material';
import { updateCorpEmpActivityAction, uploadActivityImage } from 'src/store/actions/corpempactivitymanagementAction';

const statusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const useStyles = makeStyles(() => ({
    images: {
        width: "100%",
        height: 200,
        borderRadius: 5,
        marginBottom: 15
    },
    description: {
        width: '100%',
        height: '15rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const UpdateOfflineActivity = ({ className, ...rest }) => {
    const location = useLocation();
    let { state } = location;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const isSubmitted = useSelector(state => state.corpempactivity.corpempactivityUpdate.isLoading);
    const errorMessage = useSelector(state => state.corpempactivity.corpempactivityUpdate.error);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    const decodeHTMLEntities = (text) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };

    const removePTags = (htmlContent) => {
        if (!htmlContent) return '';

        const decodedContent = decodeHTMLEntities(htmlContent);
        return decodedContent
            .replace(/<\/?p[^>]*>/g, '\n')
            .replace(/&nbsp;/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();
    };

    // Richtext editor
    const contentState = ContentState.createFromBlockArray(htmlToDraft(removePTags(state?.Description) || "").contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
    const [rhDiscription, setRhDiscription] = useState(removePTags(state?.Description));

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    // img upload
    const [image, setImage] = useState({ preview: state?.Activity_Image, raw: '' });
    const [imgUrl, setImgUrl] = useState(state?.Activity_Image);
    const [uploadPic, setUploadPic] = useState(false);

    const callBackUploadImage = data => {
        if (data) {
            setImgUrl(data.path);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        e.preventDefault();
        const file = e.target.files[0]
        if (file) {
            setImage({
                preview: URL.createObjectURL(file),
                raw: file
            });
            const data = new FormData();
            data.append('file', file);
            dispatch(
                uploadActivityImage(data, callBackUploadImage)
            );
        }
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };

    return (
        <Formik
            initialValues={{
                Activity_Id: state?.id,
                Status: state?.Status,
                Description: state?.Description,
                Activity_Name: state?.Activity_Name,
                Activity_Type: state?.Activity_Type,
                Category: state?.Category,
                Activity_Image: imgUrl,
                Allowed_Subscriptions: state?.Allowed_Subscriptions,
                Modified_By: loginEmail,
            }}
            validationSchema={
                Yup.object().shape({
                    Activity_Name: Yup.string().matches(/^.*\S.*$/, "Activity name cannot be empty or only spaces.").required("Activity name is required."),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);

                const errors = {};
                values.Description = rhDiscription;
                values.Activity_Image = imgUrl;

                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }
                if (!values.Activity_Image) {
                    submitTest = false;
                    errors.Activity_Image = "Activity Image is required.";
                }

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(updateCorpEmpActivityAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit} >
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Activity_Name && errors.Activity_Name)}
                                                fullWidth
                                                helperText={touched.Activity_Name && errors.Activity_Name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Activity name*"
                                                name="Activity_Name"
                                                value={values.Activity_Name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status*"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Card style={{
                                        border: `2px dashed blue`,
                                        textAlign: 'center',
                                        background: 'rgba(33, 150, 243, 0.08)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        maxWidth: '516px',
                                        height: '270px',
                                        minWidth: '360px'
                                    }}>
                                        <CardContent >
                                            <Box display="flex" justifyContent="center"  >
                                                {image.preview ? (
                                                    <Avatar
                                                        className={classes.images}
                                                        alt="Brand Upload"
                                                        src={image.preview}
                                                        variant="square"
                                                    />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Brand Upload"
                                                            src="/static/images/image-placeholder.png"
                                                            variant="square"
                                                        />
                                                    </>
                                                )}
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            >
                                                {image.preview ? <Button
                                                    style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    Remove Picture
                                                </Button> : <input
                                                    style={{ display: 'block', width: '80%' }}
                                                    id="upload-photo"
                                                    name="image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImage}
                                                />}
                                                <br />
                                            </Box>
                                        </CardContent>
                                    </Card><br />
                                    <span style={{ color: "red", display: "flex", justifyContent: "flex-end" }}>
                                        {!imgUrl && touched.Activity_Image && errors.Activity_Image}
                                    </span>
                                </Grid>
                            </Grid>
                        </CardContent >
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            p={3}
                        >
                            <Button
                                variant="outlined"
                                style={{ border: '1px solid #27b6cc', marginRight: 10 }}
                                component={RouterLink}
                                to="/app/corpemp-activity-management"
                            >
                                <span style={{ color: '#27B6CC' }}>CANCEL</span>
                            </Button>
                            {isSubmitted ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    disabled={isSubmitted || uploadPic}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>UPDATE</span>
                                </Button>
                            )}
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
};

UpdateOfflineActivity.propTypes = {
    className: PropTypes.string
};

export default UpdateOfflineActivity;