import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { CircularProgress, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { addCorpEmpHappinessSurveyAction } from 'src/store/actions/corpemphappinesssurveyAction';

const statusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const useStyles = makeStyles(() => ({
    description: {
        width: '100%',
        height: '15rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    }
}));

const AddHappinessSurvey = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const isSubmitted = useSelector(state => state.corpemphappinesssurvey.corpemphappinesssurveyCreate.isLoading);
    const errorMessage = useSelector(state => state.corpemphappinesssurvey.corpemphappinesssurveyCreate.error);

    // Richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const [cards, setCards] = useState([
        {
            id: Date.now(),
            Section_Name: "",
            questions: [{ Question: "" }],
            errors: {
                Section_Name: "",
                questions: [""],
            },
        },
    ]);

    const transformedResponse = cards.filter(card => card.Section_Name.trim() !== "" && card.questions.some(q => q.Question.trim() !== ""))
        .map(card => ({
            Section_Name: card.Section_Name.trim(),
            Questions: card.questions.filter(q => q.Question.trim() !== "").map(q => ({ Question: q.Question.trim(), })),
        }));

    const validateSectionName = (value) => {
        if (value.trim() === "") {
            return "Section name cannot be empty or only spaces";
        }
        return "";
    };

    const validateQuestion = (value, isFirstQuestion) => {
        if (isFirstQuestion && value.trim() === "") {
            return "First question cannot be empty or only spaces";
        } else if (!isFirstQuestion && value.trim() === "") {
            return "Question cannot be empty or only spaces";
        }
        return "";
    };

    const handleAddCard = () => {
        const hasErrors = cards.some(
            (card) =>
                validateSectionName(card.Section_Name) ||
                card.questions.some((q, index) => validateQuestion(q.Question, index === 0))
        );

        if (hasErrors) {
            setCards((prevCards) =>
                prevCards.map((card) => ({
                    ...card,
                    errors: {
                        Section_Name: validateSectionName(card.Section_Name),
                        questions: card.questions.map((q, index) =>
                            validateQuestion(q.Question, index === 0)
                        ),
                    },
                }))
            );
            return;
        }

        setCards((prevCards) => [
            ...prevCards,
            {
                id: Date.now(),
                Section_Name: "",
                questions: [{ Question: "" }],
                errors: {
                    Section_Name: "",
                    questions: [""],
                },
            },
        ]);
    };

    const handleAddQuestion = (cardId) => {
        setCards((prevCards) =>
            prevCards.map((card) => {
                if (card.id === cardId) {
                    const sectionError = validateSectionName(card.Section_Name);

                    const questionErrors = card.questions.map((q, index) =>
                        validateQuestion(q.Question, index === 0)
                    );

                    const hasUnfilledLastQuestion =
                        validateQuestion(card.questions[card.questions.length - 1].Question, card.questions.length === 1);

                    if (sectionError || hasUnfilledLastQuestion) {
                        return {
                            ...card,
                            errors: {
                                Section_Name: sectionError,
                                questions: questionErrors,
                            },
                        };
                    }

                    return {
                        ...card,
                        questions: [...card.questions, { Question: "" }],
                        errors: {
                            Section_Name: "",
                            questions: [...card.errors.questions, ""],
                        },
                    };
                }
                return card;
            })
        );
    };

    const handleDeleteQuestion = (cardId, questionIndex) => {
        setCards((prevCards) =>
            prevCards.map((card) => {
                if (card.id === cardId) {
                    const updatedQuestions = card.questions.filter((_, index) => index !== questionIndex);
                    const updatedErrors = card.errors.questions.filter((_, index) => index !== questionIndex);
                    return {
                        ...card,
                        questions: updatedQuestions,
                        errors: {
                            ...card.errors,
                            questions: updatedErrors,
                        },
                    };
                }
                return card;
            })
        );
    };

    const handleDeleteSection = (cardId) => {
        setCards((prevCards) => prevCards.filter((card) => card.id !== cardId));
    };

    const handleInputChange = (cardId, field, value, questionIndex = null) => {
        setCards((prevCards) =>
            prevCards.map((card) => {
                if (card.id === cardId) {
                    if (field === "Section_Name") {
                        return {
                            ...card,
                            Section_Name: value,
                            errors: {
                                ...card.errors,
                                Section_Name: validateSectionName(value),
                            },
                        };
                    } else {
                        return {
                            ...card,
                            questions: card.questions.map((q, index) =>
                                index === questionIndex ? { ...q, [field]: value } : q
                            ),
                            errors: {
                                ...card.errors,
                                questions: card.errors.questions.map((error, index) => {
                                    if (index === questionIndex) {
                                        return validateQuestion(value, questionIndex === 0);
                                    }
                                    return error;
                                }),
                            },
                        };
                    }
                }
                return card;
            })
        );
    };

    return (
        <Formik
            initialValues={{
                Status: '',
                Description: '',
                Survey_Title: '',
                Sections: '',
            }}
            validationSchema={
                Yup.object().shape({
                    Survey_Title: Yup.string().matches(/^.*\S.*$/, "Survey title cannot be empty or only spaces.").required("Survey title is required."),
                    Status: Yup.string().required('Status is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);

                const errors = {};
                values.Description = rhDiscription;

                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    formValues.Sections = transformedResponse
                    dispatch(addCorpEmpHappinessSurveyAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit} >
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Survey_Title && errors.Survey_Title)}
                                                fullWidth
                                                helperText={touched.Survey_Title && errors.Survey_Title}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Survey title*"
                                                name="Survey_Title"
                                                value={values.Survey_Title}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(touched.Status && errors.Status)}
                                                fullWidth
                                                helperText={touched.Status && errors.Status}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label="Status"
                                                name="Status"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{ native: true }}
                                                value={values.Status}
                                                variant="outlined"
                                            >
                                                {statusOption.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Box className={classes.description}>
                                                Description*
                                                <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                            </Box>
                                            <span style={{ color: "red" }}>
                                                {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent >
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>
                    </Card>

                    {cards.map((card) => (
                        <Card key={card.id} style={{ marginTop: 20 }}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Section name"
                                            name={`Section_Name_${card.id}`}
                                            value={card.Section_Name}
                                            onChange={(e) =>
                                                handleInputChange(card.id, "Section_Name", e.target.value)
                                            }
                                            error={!!card.errors.Section_Name}
                                            helperText={card.errors.Section_Name}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        {card.questions.map((question, index) => (
                                            <React.Fragment key={`${card.id}_question_${index}`}>
                                                <Grid container spacing={3} key={index} alignItems="center">
                                                    <Grid item md={8} xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label={`Question ${index + 1}*`}
                                                            name={`Question_${index}`}
                                                            value={question.Question}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    card.id,
                                                                    "Question",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                            error={!!card.errors.questions[index]}
                                                            helperText={card.errors.questions[index]}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    {card.questions.length > 1 && (
                                                        <Grid
                                                            item
                                                            md={1}
                                                            xs={12}
                                                            style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                                                        >
                                                            <img
                                                                src="/static/elev8-delete.png"
                                                                alt="Delete Question"
                                                                style={{ width: 24, height: 24, cursor: "pointer" }}
                                                                onClick={() => handleDeleteQuestion(card.id, index)}
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item md={8} xs={12}>
                                                        <RadioGroup
                                                            row
                                                            style={{
                                                                justifyContent: "space-between",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {[
                                                                "Strongly Disagree",
                                                                "Disagree",
                                                                "Neutral",
                                                                "Agree",
                                                                "Strongly Agree",
                                                            ].map((option, idx) => (
                                                                <FormControlLabel
                                                                    key={idx}
                                                                    value={option}
                                                                    control={<Radio disabled />}
                                                                    label={option}
                                                                    labelPlacement="top"
                                                                    style={{
                                                                        flex: 1,
                                                                        textAlign: "center",
                                                                        margin: "0",
                                                                    }}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ marginTop: 15, marginBottom: 30 }} />
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{ marginTop: 10 }}
                                >
                                    <img
                                        src="/static/elev8-plus.png"
                                        alt="Add Question"
                                        style={{ width: 24, height: 24, marginRight: 10, cursor: "pointer" }}
                                        onClick={() => handleAddQuestion(card.id)}
                                    />
                                    <img
                                        src="/static/elev8-section.png"
                                        alt="Add Card"
                                        style={{ width: 24, height: 24, marginRight: 10, cursor: "pointer" }}
                                        onClick={handleAddCard}
                                    />
                                    <img
                                        src="/static/elev8-delete.png"
                                        alt="Delete Section"
                                        style={{ width: 24, height: 24, cursor: "pointer", marginBottom: 2 }}
                                        onClick={() => handleDeleteSection(card.id)}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    ))}

                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        p={3}
                    >
                        <Button
                            variant="outlined"
                            style={{ border: '1px solid #27b6cc', marginRight: 10 }}
                            component={RouterLink}
                            to="/app/corpemp-happiness-survey-management"
                        >
                            <span style={{ color: '#27B6CC' }}>CANCEL</span>
                        </Button>

                        {isSubmitted ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                disabled={isSubmitted || (!transformedResponse || !transformedResponse.some(section => section?.Section_Name?.trim() !== "" && section.Questions?.some(question => question?.Question?.trim() !== "")))}
                                type="submit"
                                style={{ backgroundColor: '#27B6CC' }}
                                variant="contained"
                            >
                                <span style={{ color: 'white' }}>SAVE</span>
                            </Button>
                        )}
                    </Box>
                </form>
            )
            }
        </Formik >
    );
};

AddHappinessSurvey.propTypes = {
    className: PropTypes.string
};

export default AddHappinessSurvey;