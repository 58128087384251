import { toast } from 'react-toastify';
import { paginationAllCorpEmpHappinessSurveyGet } from './node-actions/nodepagination';
import { nodeCorpMongoApi } from './commonAxios';

export const CORPEMP_HAPPINESS_SURVEY_CREATE_REQUEST = "CORPEMP_HAPPINESS_SURVEY_CREATE_REQUEST";
export const CORPEMP_HAPPINESS_SURVEY_CREATE_SUCCESS = "CORPEMP_HAPPINESS_SURVEY_CREATE_SUCCESS";
export const CORPEMP_HAPPINESS_SURVEY_CREATE_FAIL = "CORPEMP_HAPPINESS_SURVEY_CREATE_FAIL";

export const CORPEMP_HAPPINESS_SURVEY_UPDATE_REQUEST = "CORPEMP_HAPPINESS_SURVEY_UPDATE_REQUEST";
export const CORPEMP_HAPPINESS_SURVEY_UPDATE_SUCCESS = "CORPEMP_HAPPINESS_SURVEY_UPDATE_SUCCESS";
export const CORPEMP_HAPPINESS_SURVEY_UPDATE_FAIL = "CORPEMP_HAPPINESS_SURVEY_UPDATE_FAIL";

//Create
export const addCorpEmpHappinessSurveyAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPEMP_HAPPINESS_SURVEY_CREATE_REQUEST
    });
    nodeCorpMongoApi.post(`/corpHappinessIndexSurvey/create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 201) {
                dispatch({
                    type: CORPEMP_HAPPINESS_SURVEY_CREATE_SUCCESS
                });
                toast('Happiness Survey added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corpemp-happiness-survey-management');
            } else {
                dispatch({
                    type: CORPEMP_HAPPINESS_SURVEY_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPEMP_HAPPINESS_SURVEY_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//Update
export const updateCorpEmpHappinessSurveyAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPEMP_HAPPINESS_SURVEY_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`/corpHappinessIndexSurvey/update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 200) {
                dispatch({
                    type: CORPEMP_HAPPINESS_SURVEY_UPDATE_SUCCESS
                });
                toast('Happiness Survey updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corpemp-happiness-survey-management');
            } else {
                dispatch({
                    type: CORPEMP_HAPPINESS_SURVEY_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPEMP_HAPPINESS_SURVEY_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Delete
export const deleteCorpEmpHappinessSurveyAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCorpMongoApi.post(`/corpHappinessIndexSurvey/delete`, formData);
        if (data) {
            toast('Happiness Survey deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationAllCorpEmpHappinessSurveyGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};