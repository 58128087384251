import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles,
    Avatar,
    Tabs,
    Tab,
    Dialog,
    Typography,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    Divider
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import { ConnectedFocusError } from "focus-formik-error";
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { addCorpEmpQuizAction, uploadActivityImage } from 'src/store/actions/corpempactivitymanagementAction';
import { Autocomplete } from '@material-ui/lab';
// import { Add, Delete, RemoveRedEye } from "@mui/icons-material";
import { Add, Delete, Visibility, ArrowUpward, ArrowDownward } from "@mui/icons-material";

const statusOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const levelOptions = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Easy',
        label: 'Easy'
    },
    {
        value: 'Medium',
        label: 'Medium'
    },
    {
        value: 'Hard',
        label: 'Hard'
    },
];

const useStyles = makeStyles(theme => ({
    images: {
        width: "100%",
        height: 300,
        borderRadius: 5,
        marginBottom: 15,
        marginTop: 15
    },
    description: {
        width: '100%',
        height: '15rem',
        padding: '1rem',
        overflow: 'auto',
        border: '1px solid grey'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
}));

const quiztypes = [
    {
        title: 'Multiple choice',
        image: '/static/elev8-multiple-choice.png',
        path: '/app/create-corpemp-quiz',
    },
    {
        title: 'True or false',
        image: '/static/elev8-true-false.png',
        path: '/app/create-corpemp-quiz',
    },
    {
        title: 'Picture quiz',
        image: '/static/elev8-picture-quiz.png',
        path: '/app/create-corpemp-quiz',
    }
];

const CreateQuiz = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const [tabvalue, setTabValue] = useState("one");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(true);
    }, []);

    const handleClose = () => {
        setOpen(false);
        navigate('/app/corpemp-activity-management');
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleNavigation = (path) => {
        setOpen(false);
        navigate(path);
    };

    const isSubmitted = useSelector(state => state.corpempactivity.corpempquizCreate.isLoading);
    const errorMessage = useSelector(state => state.corpempactivity.corpempquizCreate.error);

    // Richtext editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [rhDiscription, setRhDiscription] = useState('');
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    // img upload
    const [image, setImage] = useState({ preview: '', raw: '' });
    const [imgUrl, setImgUrl] = useState('');
    const [uploadPic, setUploadPic] = useState(false);

    const callBackUploadImage = data => {
        if (data) {
            setImgUrl(data.path);
        }
        setUploadPic(false);
    };

    const handleImage = e => {
        e.preventDefault();
        const file = e.target.files[0]
        if (file) {
            setImage({
                preview: URL.createObjectURL(file),
                raw: file
            });
            const data = new FormData();
            data.append('file', file);
            dispatch(
                uploadActivityImage(data, callBackUploadImage)
            );
        }
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };

    // const [selectedTab, setSelectedTab] = useState(0);
    // const [selectedDifficulty, setSelectedDifficulty] = useState("Easy");
    const [previewOpen, setPreviewOpen] = useState(false);
    // const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    // const [nextDifficulty, setNextDifficulty] = useState(null);

    // const handleQuestionTabChange = (event, newValue) => setSelectedTab(newValue);

    // const [quizData, setQuizData] = useState({
    //     Quiz_Difficulty_Levels: ["Easy", "Medium", "Hard"],
    //     Difficulty_Level_Vise_Questions: [
    //         { Difficulty_Level_Name: "Easy", Questions: [] },
    //         { Difficulty_Level_Name: "Medium", Questions: [] },
    //         { Difficulty_Level_Name: "Hard", Questions: [] }
    //     ]
    // });

    // const handleDifficultyChange = (level) => {
    //     const currentQuestions = quizData.Difficulty_Level_Vise_Questions.find(q => q.Difficulty_Level_Name === selectedDifficulty)?.Questions.length;
    //     if (currentQuestions === 0) {
    //         setNextDifficulty(level);
    //         setConfirmDialogOpen(true);
    //     } else {
    //         setSelectedDifficulty(level);
    //         setSelectedTab(0);
    //     }
    // };

    const [selectedTab, setSelectedTab] = useState(0);

const handleQuestionTabChange = (event, newValue) => {
    setSelectedTab(newValue);
};

    const [selectedDifficulty, setSelectedDifficulty] = useState("easy");
    const [quizData, setQuizData] = useState({
        easy: [],
        medium: [],
        hard: []
    });

    // console.log(quizData, "quizData")

    // Create a new question with default options
    const createNewQuestion = () => ({
        id: Date.now(),
        text: "",
        options: [
            { id: Date.now() + 1, text: "", Is_Currect_Option: false },
            { id: Date.now() + 2, text: "", Is_Currect_Option: false },
            { id: Date.now() + 3, text: "", Is_Currect_Option: false },
            { id: Date.now() + 4, text: "", Is_Currect_Option: false }
        ]
    });

    const [currentQuestion, setCurrentQuestion] = useState(createNewQuestion());

    // Add a question
    // const addQuestion = () => {
    //     setQuizData(prev => ({
    //         ...prev,
    //         [selectedDifficulty]: [...prev[selectedDifficulty], createNewQuestion()]
    //     }));
    // };
    const addQuestion = () => {
        // if (currentQuestion.text.trim() === "" || currentQuestion.options.some(opt => opt.text.trim() === "")) {
        //     alert("Please fill in the question and all options before adding a new question.");
        //     return;
        // }

        setQuizData(prev => ({
            ...prev,
            [selectedDifficulty]: [...prev[selectedDifficulty], currentQuestion]
        }));

        setCurrentQuestion(createNewQuestion());
        setSelectedTab(quizData[selectedDifficulty].length);
    };

    // const addQuestion = () => {
    
    //     setQuizData(prev => ({
    //         ...prev,
    //         [selectedDifficulty]: [
    //             ...prev[selectedDifficulty],
    //             {
    //                 Question: currentQuestion.text,
    //                 Options: currentQuestion.options.map(opt => ({
    //                     Option: opt.text,
    //                     Is_Currect_Option: opt.Is_Currect_Option
    //                 }))
    //             }
    //         ]
    //     }));
    
    //     // Reset to a new empty question
    //     setCurrentQuestion({
    //         text: "",
    //         options: [
    //             { id: Date.now() + 1, text: "", Is_Currect_Option: false },
    //             { id: Date.now() + 2, text: "", Is_Currect_Option: false },
    //             { id: Date.now() + 3, text: "", Is_Currect_Option: false },
    //             { id: Date.now() + 4, text: "", Is_Currect_Option: false }
    //         ]
    //     });
    // };

    const handleQuestionChange = (e) => {
        const value = e.target.value; // Store value before setState
        setCurrentQuestion(prev => ({
            ...prev,
            text: value
        }));
    };

    const handleOptionChange = (optionId, value) => {
        setCurrentQuestion(prev => ({
            ...prev,
            options: prev.options.map(opt =>
                opt.id === optionId ? { ...opt, text: value } : opt
            )
        }));
    };


    // Remove a question
    // const removeQuestion = (questionId) => {
    //     setQuizData(prev => ({
    //         ...prev,
    //         [selectedDifficulty]: prev[selectedDifficulty].filter(q => q.id !== questionId)
    //     }));
    // };
    const removeQuestion = () => {
        setQuizData(prev => ({
            ...prev,
            [selectedDifficulty]: prev[selectedDifficulty].filter(q => q.Question !== currentQuestion.text)
        }));
    
        // Reset to an empty question state
        setCurrentQuestion(createNewQuestion());
    };
    

    // Add an option
    const addOption = (questionId) => {
        setQuizData(prev => ({
            ...prev,
            [selectedDifficulty]: prev[selectedDifficulty].map(q =>
                q.id === questionId
                    ? { ...q, options: [...q.options, { id: Date.now(), text: "", Is_Currect_Option: false }] }
                    : q
            )
        }));
    };

    // Remove an option
    // const removeOption = (questionId, optionId) => {
    //     setQuizData(prev => ({
    //         ...prev,
    //         [selectedDifficulty]: prev[selectedDifficulty].map(q =>
    //             q.id === questionId
    //                 ? { ...q, options: q.options.filter(opt => opt.id !== optionId) }
    //                 : q
    //         )
    //     }));
    // };
    const removeOption = (optionId) => {
        setCurrentQuestion(prev => ({
            ...prev,
            options: prev.options.filter(opt => opt.id !== optionId)
        }));
    };
    

    // Toggle correct option
    // const toggleCorrectOption = (questionId, optionId) => {
    //     setQuizData(prev => ({
    //         ...prev,
    //         [selectedDifficulty]: prev[selectedDifficulty].map(q =>
    //             q.id === questionId
    //                 ? {
    //                     ...q,
    //                     options: q.options.map(opt =>
    //                         opt.id === optionId ? { ...opt, Is_Currect_Option: !opt.Is_Currect_Option } : opt
    //                     )
    //                 }
    //                 : q
    //         )
    //     }));
    // };
    const toggleCorrectOption = (questionId, optionId) => {
        setCurrentQuestion(prev => ({
            ...prev,
            options: prev.options.map(opt =>
                opt.id === optionId ? { ...opt, Is_Currect_Option: !opt.Is_Currect_Option } : opt
            )
        }));
    };

    // Convert state into required JSON structure
    // const getQuizJSON = () => ({
    //     Quiz_Difficulty_Levels: ["easy", "medium", "hard"],
    //     Difficulty_Level_Vise_Questions: Object.entries(quizData).map(([level, questions]) => ({
    //         Difficulty_Level_Name: level,
    //         Questions: questions.map(q => ({
    //             Question: q.text,
    //             Options: q.options.map(opt => ({
    //                 Option: opt.text,
    //                 Is_Currect_Option: opt.Is_Currect_Option
    //             }))
    //         }))
    //     }))
    // });

    return (
        <Formik
            initialValues={{
                Status: '',
                Description: '',
                Category: '',
                Available_Levels: '',
                Quiz_Name: '',
                Image: imgUrl
            }}
            validationSchema={
                Yup.object().shape({
                    Quiz_Name: Yup.string().matches(/^.*\S.*$/, "Quiz name cannot be empty or only spaces.").required("Quiz name is required."),
                    Status: Yup.string().required('Status is required.'),
                    Category: Yup.string().required('Category is required.'),
                    Available_Levels: Yup.string().required('Available levels is required.'),
                })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
                let submitTest = true;
                setSubmitting(false);

                const errors = {};
                values.Description = rhDiscription;
                values.Image = imgUrl;

                if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length === 0) {
                    submitTest = false;
                    errors.Description = "Description is required.";
                }
                else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                    submitTest = false;
                    errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                }
                if (!values.Image) {
                    submitTest = false;
                    errors.Image = "Image is required.";
                }

                setErrors(errors);
                if (submitTest) {
                    let formValues = JSON.parse(JSON.stringify(values));
                    dispatch(addCorpEmpQuizAction(formValues, navigate));
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit} >
                    <ConnectedFocusError />
                    <Card>
                        <CardContent>
                            <Box sx={{ width: '100%', marginBottom: 15 }}>
                                <Box sx={{ borderBottom: 5, borderColor: 'divider' }}>
                                    <Tabs value={tabvalue} onChange={handleTabChange} aria-label="basic tabs example">
                                        <Tab label="QUIZ SETUP" value="one" />
                                        <Tab label="QUESTIONS" value="two" />
                                    </Tabs>
                                </Box>
                            </Box>
                            {tabvalue == "one" && (
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.Quiz_Name && errors.Quiz_Name)}
                                                    fullWidth
                                                    helperText={touched.Quiz_Name && errors.Quiz_Name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Quiz name*"
                                                    name="Quiz_Name"
                                                    value={values.Quiz_Name}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.Category && errors.Category)}
                                                    fullWidth
                                                    helperText={touched.Category && errors.Category}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Category"
                                                    name="Category"
                                                    select
                                                    InputLabelProps={{ shrink: true }}
                                                    SelectProps={{ native: true }}
                                                    value={values.Category}
                                                    variant="outlined"
                                                ><option key="" value="">--Please Select--</option>
                                                    {statusOption.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    multiple
                                                    fullWidth
                                                    id="tags-standard"
                                                    options={levelOptions}
                                                    getOptionLabel={(option) => option.label}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Available levels"
                                                            value={values.Available_Levels}
                                                            onChange={handleChange}
                                                            error={Boolean(touched.Available_Levels && errors.Available_Levels)}
                                                            helperText={touched.Available_Levels && errors.Available_Levels}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Box className={classes.description}>
                                                    Description
                                                    <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                                </Box>
                                                <span style={{ color: "red" }}>
                                                    {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Description && errors.Description}
                                                </span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.Status && errors.Status)}
                                                    fullWidth
                                                    helperText={touched.Status && errors.Status}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Status*"
                                                    name="Status"
                                                    select
                                                    InputLabelProps={{ shrink: true }}
                                                    SelectProps={{ native: true }}
                                                    value={values.Status}
                                                    variant="outlined"
                                                >
                                                    {statusOption.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Card style={{
                                            border: `2px dashed blue`,
                                            textAlign: 'center',
                                            background: 'rgba(33, 150, 243, 0.08)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            maxWidth: '516px',
                                            height: '426px',
                                            minWidth: '500px'
                                        }}>
                                            <CardContent >
                                                <Box display="flex" justifyContent="center"  >
                                                    {image.preview ? (
                                                        <Avatar
                                                            className={classes.images}
                                                            alt="Brand Upload"
                                                            src={image.preview}
                                                            variant="square"
                                                        />
                                                    ) : (
                                                        <>
                                                            <Avatar
                                                                className={classes.images}
                                                                alt="Brand Upload"
                                                                src="/static/images/image-placeholder.png"
                                                                variant="square"
                                                            />
                                                        </>
                                                    )}
                                                </Box>

                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                >
                                                    {image.preview ? <Button
                                                        style={{ fontSize: '10', color: ' #425A70 ', textTransform: 'none' }}
                                                        onClick={handleRemoveImage}
                                                    >
                                                        Remove Picture
                                                    </Button> : <input
                                                        style={{ display: 'block', width: '80%' }}
                                                        id="upload-photo"
                                                        name="image"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleImage}
                                                    />}
                                                    <br />
                                                </Box>

                                            </CardContent>
                                        </Card><br />
                                        <span style={{ color: "red", display: "flex", justifyContent: "flex-end" }}>
                                            {!imgUrl && touched.Image && errors.Image}
                                        </span>
                                    </Grid>
                                </Grid>
                            )}
                            {tabvalue == "two" && (
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Card sx={{ p: 2 }}>
                                            <Typography style={{ display: 'flex', margin: 10, fontWeight: 'bold' }}>
                                                Questions
                                            </Typography>
                                            {/* <Tabs orientation="vertical" value={selectedTab} onChange={handleQuestionTabChange}>
                                                {quizData.Difficulty_Level_Vise_Questions?.find(q => q.Difficulty_Level_Name === selectedDifficulty)?.Questions.map((q, index) => (
                                                    <Tab key={index} label={`Question ${index + 1}`} />
                                                ))}
                                            </Tabs> */}
                                             <Tabs
                orientation="vertical"
                value={selectedTab}
                onChange={handleQuestionTabChange}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                {/* Show added questions only */}
                {quizData[selectedDifficulty]?.map((q, index) => (
                    <Tab key={index} label={`Question ${index + 1}`} />
                ))}

                {/* Show 'Typing...' if user is currently adding a new question */}
                {currentQuestion.text.trim() !== "" && (
                    <Tab label="Typing..." disabled />
                )}
            </Tabs>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card sx={{ p: 2 }}>
                                            <Box display="flex" gap={1} justifyContent="space-between" alignItems="center" flexWrap="nowrap">
                                                <Typography style={{ marginRight: 2, marginLeft: 2 }}>Difficulty levels :</Typography>
                                                <Box display="flex" gap={2}>
                                                    {["easy", "medium", "hard"].map(level => (
                                                        <Button
                                                            key={level}
                                                            variant={selectedDifficulty === level ? "contained" : "outlined"}
                                                            onClick={() => setSelectedDifficulty(level)}
                                                            style={{
                                                                color: selectedDifficulty === level ? "white" : "black",
                                                                backgroundColor: selectedDifficulty === level && level === 'easy' ? 'green' : selectedDifficulty === level && level === 'medium' ? 'orange' : selectedDifficulty === level && level === 'hard' ? 'red' : 'white',
                                                                borderRadius: 20,
                                                                minWidth: 80,
                                                                marginRight: 5
                                                            }}
                                                        >
                                                            {level}
                                                        </Button>
                                                    ))}
                                                </Box>

                                                <Box display="flex" alignItems="center" gap={0.5}>
                                                    <Typography style={{ fontSize: "0.875rem" }}>PREVIEW</Typography>
                                                    <IconButton onClick={() => setPreviewOpen(true)}>
                                                        <Visibility />
                                                    </IconButton>
                                                </Box>

                                                <Button onClick={addQuestion} endIcon={<Add />}>Add Question</Button>
                                            </Box>

                                            <Divider style={{ marginTop: 5, marginBottom: 5 }} />

                                            {/* {quizData[selectedDifficulty].length === 0 ? (
                                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={5}>
                                                    <img src="/static/elev8-no-data.png" alt="No Data Found" width="536" height="400" />
                                                </Box>
                                            ) : (
                                                quizData[selectedDifficulty]?.map((question, qIndex) => (
                                                    <Box key={question.id} p={5}>
                                                        <Box display="flex" alignItems="center">
                                                            <Box
                                                                sx={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    borderRadius: "110%",
                                                                    backgroundColor: "#7f8c8d",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    color: 'white',
                                                                    marginLeft: -10,
                                                                    marginRight: 6
                                                                }}
                                                            >
                                                                <Typography variant="h6">
                                                                    {String(qIndex + 1).padStart(2, "0")}
                                                                </Typography>
                                                            </Box>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                placeholder="Start typing your question......"
                                                                value={question.text}
                                                                onChange={(e) => {
                                                                    setQuizData(prev => ({
                                                                        ...prev,
                                                                        [selectedDifficulty]: prev[selectedDifficulty].map(q =>
                                                                            q.id === question.id ? { ...q, text: e.target.value } : q
                                                                        )
                                                                    }));
                                                                }}
                                                            />
                                                            <IconButton onClick={() => removeQuestion(question.id)}><Delete /></IconButton>
                                                        </Box>
                                                        <Typography style={{ display: 'flex', margin: 5 }}>
                                                            Please select the correct options(s)
                                                        </Typography>
                                                        {question.options.map((option, oIndex) => (
                                                            <Box key={option.id} display="flex" alignItems="center" mt={1}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={option.Is_Currect_Option}
                                                                    onChange={() => toggleCorrectOption(question.id, option.id)}
                                                                    style={{ width: 20, height: 20, cursor: "pointer", marginRight: 15 }}
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    placeholder={`Option ${oIndex + 1}`}
                                                                    value={option.text}
                                                                    onChange={(e) => {
                                                                        setQuizData(prev => ({
                                                                            ...prev,
                                                                            [selectedDifficulty]: prev[selectedDifficulty].map(q =>
                                                                                q.id === question.id
                                                                                    ? {
                                                                                        ...q,
                                                                                        options: q.options.map(opt =>
                                                                                            opt.id === option.id ? { ...opt, text: e.target.value } : opt
                                                                                        ),
                                                                                    }
                                                                                    : q
                                                                            )
                                                                        }));
                                                                    }}
                                                                    sx={{ ml: 2 }}
                                                                />
                                                                <IconButton onClick={() => removeOption(question.id, option.id)}><Delete /></IconButton>
                                                            </Box>
                                                        ))}

                                                        <Button onClick={() => addOption(question.id)} sx={{ mt: 1 }}>
                                                            Add Option
                                                        </Button>
                                                    </Box>
                                                ))
                                            )} */}
                                            {quizData[selectedDifficulty].length === 0 && currentQuestion.text.trim() === "" ? (
                                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={5}>
                                                    <img src="/static/elev8-no-data.png" alt="No Data Found" width="536" height="400" />
                                                </Box>
                                            ) : (
                                                <Box p={5}>
                                                    <Box display="flex" alignItems="center">
                                                        <Box
                                                            sx={{
                                                                width: 40,
                                                                height: 40,
                                                                borderRadius: "110%",
                                                                backgroundColor: "#7f8c8d",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                color: 'white',
                                                                marginLeft: -10,
                                                                marginRight: 6
                                                            }}
                                                        >
                                                            <Typography variant="h6">
                                                                {String((quizData[selectedDifficulty].length - 1) + 1).padStart(2, "0")}
                                                            </Typography>
                                                        </Box>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder="Start typing your question..."
                                                            value={currentQuestion.text}
                                                            onChange={handleQuestionChange}
                                                        />
                                                        <IconButton onClick={removeQuestion}><Delete /></IconButton>
                                                    </Box>

                                                    <Typography style={{ display: 'flex', margin: 5 }}>Please select the correct option(s)</Typography>

                                                    {currentQuestion.options.map((option, oIndex) => (
                                                        <Box key={option.id} display="flex" alignItems="center" mt={1}>
                                                            <input
                                                                type="checkbox"
                                                                checked={option.Is_Currect_Option}
                                                                onChange={() => toggleCorrectOption(currentQuestion.id, option.id)}
                                                                style={{ width: 20, height: 20, cursor: "pointer", marginRight: 15 }}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                placeholder={`Option ${oIndex + 1}`}
                                                                value={option.text}
                                                                onChange={(e) => handleOptionChange(option.id, e.target.value)}
                                                                sx={{ ml: 2 }}
                                                            />
                                                            <IconButton onClick={() => removeOption(option.id)}><Delete /></IconButton>
                                                        </Box>
                                                    ))}

                                                    {/* <Button onClick={addQuestion} sx={{ mt: 2 }} variant="contained">Add Question</Button> */}
                                                </Box>
                                            )}

                                        </Card>
                                    </Grid>

                                    <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
                                        <DialogTitle>Preview Questions</DialogTitle>
                                        {/* <DialogContent>
                                            {quizData.Difficulty_Level_Vise_Questions.find(q => q.Difficulty_Level_Name === selectedDifficulty)?.Questions.map((q, index) => (
                                                <Box key={index} mb={2}>
                                                    <Typography variant="h6">{index + 1}. {q.Question}</Typography>
                                                    {q.Options.map((opt, i) => (
                                                        <Typography key={i}>{String.fromCharCode(65 + i)}. {opt.Option}</Typography>
                                                    ))}
                                                </Box>
                                            ))}
                                        </DialogContent> */}
                                        <DialogActions>
                                            <Button onClick={() => setPreviewOpen(false)}>Close</Button>
                                        </DialogActions>
                                    </Dialog>

                                    {/* <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                                        <DialogTitle>Proceed to Next Level?</DialogTitle>
                                        <DialogContent>
                                            <Typography>You haven't added any questions in this difficulty level. Are you sure you want to proceed?</Typography>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
                                            <Button onClick={confirmDifficultyChange} color="primary">Proceed</Button>
                                        </DialogActions>
                                    </Dialog> */}
                                </Grid>
                            )}
                        </CardContent >
                        <Box display="flex" pl={2} >
                            <span style={{ color: "red" }}>{errorMessage}</span>
                        </Box>
                    </Card>
                    {tabvalue == "one" && (
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            p={3}
                        >
                            <Button
                                variant="outlined"
                                style={{ border: '1px solid #27b6cc', marginRight: 10 }}
                                component={RouterLink}
                                to="/app/corpemp-activity-management"
                            >
                                <span style={{ color: '#27B6CC' }}>CANCEL</span>
                            </Button>

                            {isSubmitted ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>CONTINUE</span>
                                </Button>
                            )}
                        </Box>
                    )}
                    {tabvalue == "two" && (
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            p={3}
                        >
                            <Button
                                variant="outlined"
                                style={{ border: '1px solid #27b6cc', marginRight: 10 }}
                                component={RouterLink}
                                to="/app/corpemp-activity-management"
                            >
                                <span style={{ color: '#27B6CC' }}>CANCEL</span>
                            </Button>

                            <Button
                                variant="outlined"
                                style={{ border: '1px solid #27b6cc', marginRight: 10 }}
                            // onClick={handleSaveAndContinue}
                            >
                                <span style={{ color: '#27B6CC' }}>SAVE & CONTINUE</span>
                            </Button>

                            {isSubmitted ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    disabled={isSubmitted}
                                    type="submit"
                                    style={{ backgroundColor: '#27B6CC' }}
                                    variant="contained"
                                >
                                    <span style={{ color: 'white' }}>CREATE</span>
                                </Button>
                            )}
                        </Box>
                    )}
                    <Dialog
                        open={open}
                        onClose={null}
                        fullWidth
                        maxWidth="sm"
                    >
                        <IconButton onClick={handleClose} className={classes.closeButton}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h3" style={{ marginTop: 40, marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Add Question
                        </Typography>
                        <Typography style={{ marginTop: 10, marginBottom: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Choose the type of question that you would like to create.
                        </Typography>
                        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: 10 }}>
                            {quiztypes.map((activity, index) => (
                                <Grid item key={index} xs={3}>
                                    <Card
                                        variant="outlined"
                                        onClick={() => handleNavigation(activity.path)}
                                        style={{ marginRight: 10 }}
                                    >
                                        <img
                                            src={activity.image}
                                            alt={activity.title}
                                            style={{
                                                width: '80%',
                                                height: '80%',
                                                objectFit: 'cover',
                                                marginLeft: 12,
                                                marginTop: 8
                                            }}
                                        />
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Dialog>
                </form>
            )}
        </Formik >
    );
};

CreateQuiz.propTypes = {
    className: PropTypes.string
};

export default CreateQuiz;