import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography,
    Dialog,
    IconButton,
    FormGroup,
    FormControlLabel,
    Radio,
    Grid,
    Checkbox
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getRolePermissions } from 'src/store/actions/dynamicrolepermissionAction';
import { paginationAllCorpEmpActivityGet } from 'src/store/actions/node-actions/nodepagination';
import CloseIcon from '@mui/icons-material/Close';
import { deleteCorpEmpActivityAction, updateCorpEmpActivityAction } from 'src/store/actions/corpempactivitymanagementAction';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
}));

const ActivityManagementListView = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const loginEmail = useSelector(state => state.auth.user.Email_Id);
    const [activityManagement, setActivityManagement] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteData, setDeleteData] = useState('');
    const [selected, setSelected] = useState(deleteData?.Allowed_Subscriptions || []);

    const options = [
        { label: "Bronze", value: "BRONZE" },
        { label: "Gold", value: "GOLD" },
        { label: "Platinum", value: "PLATINUM" },
    ];

    //permissions
    const RolePermissions = useSelector(state => state.rolepermission.Roles);
    const roleUserType = useSelector(state => state.auth.user);
    const Login_Details = useSelector(state => state.auth.user);

    const [filters, setFilters] = React.useState({
        "Search": '',
        "Search_By_Filter": 'All'
    });

    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = useState({ page: 1, limit: 10, sortBy: "createdAt:desc" })

    const handleLimitChange = event => {
        setPagination({ ...pagination, limit: +event.target.value, page: 1, sortBy: "createdAt:desc" });
    };

    const handlePageChange = (event, newPage) => {
        setPagination({ ...pagination, limit: pagination.limit, page: newPage + 1, sortBy: "createdAt:desc" });
    };

    const callBackPagination = async (status, data, pagination) => {
        if (status) {
            setActivityManagement(data);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ page: 1, limit: 10, sortBy: "createdAt:desc" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            activityManagementCall();
        }
    }, [pagination])

    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if (JSON.stringify(testFilters) !== JSON.stringify(filters)) {
            setTestFilters(filters);
            searchFunction()
        }
    }, [filters]);

    const searchFunction = () => {
        const searchpagination = {
            page: 1, limit: 10, sortBy: "createdAt:desc"
        }
        dispatch(paginationAllCorpEmpActivityGet(filters, searchpagination, callBackPagination))
    }

    const activityManagementCall = () => {
        dispatch(paginationAllCorpEmpActivityGet(filters, pagination, callBackPagination))
    };

    useEffect(() => {
        dispatch(getRolePermissions(Login_Details));
        dispatch(paginationAllCorpEmpActivityGet(filters, pagination, callBackPagination))
    }, []);

    // Sorting 
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('createdAt');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPagination({ page: 1, limit: pagination?.limit, sortBy: order === 'asc' ? "createdAt:asc" : "createdAt:desc" })
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    useEffect(() => {
        if (deleteData?.Allowed_Subscriptions) {
            setSelected(deleteData.Allowed_Subscriptions);
        }
    }, [deleteData]);

    const handleChange = (event) => {
        const { name, checked } = event.target;

        if (name === "ALL") {
            setSelected(checked ? options.map((o) => o.value) : []);
        } else {
            let newSelected = checked
                ? [...selected, name]
                : selected.filter((item) => item !== name);

            setSelected(newSelected.length === options.length ? options.map((o) => o.value) : newSelected);
        }
    };

    const handleConfirm = () => {
        const formValues = {
            Activity_Id: deleteData?.id,
            Modified_By: loginEmail,
            Allowed_Subscriptions: selected
        };
        dispatch(deleteCorpEmpActivityAction(formValues, filters, { page: 1, limit: 10, sortBy: "createdAt:desc" }, callBackPagination));
        setOpen(false);
    };

    //Delete handler
    const deleteActivityManagementHandler = activityManagementData => async e => {
        setOpen(true);
        setDeleteData(activityManagementData)
    };

    return (
        <Page className={classes.root} title="ESI Admin Panel">
            <Container maxWidth={false}>
                <Button
                    component={RouterLink}
                    to="/app/corporate-management"
                    variant="text"
                    size="small"
                    color="inherit"
                >
                    <KeyboardBackspaceIcon />
                    Go back
                </Button>
                <Box py={2}>
                    <Typography variant="h2" gutterBottom>
                        Activity Management
                    </Typography>
                </Box>
                <Toolbar filters={filters} setfilters={setFilters} roleUserType={roleUserType} />
                <Box mt={3}>
                    <Results
                        allActivityManagement={activityManagement}
                        allActivityManagementNoFilter={activityManagement}
                        deleteActivityManagementHandler={deleteActivityManagementHandler}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        pagination={pagination}
                        setPagination={setPagination}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        RolePermissions={RolePermissions}
                        roleUserType={roleUserType}
                    />
                </Box>
            </Container>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="xs"
                sx={{
                    width: 100,
                    height: 400,
                    borderRadius: '4px 0px 0px 0px',
                }}>
                <IconButton onClick={() => setOpen(false)} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h3" style={{ marginTop: 40, marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Disable activity
                </Typography>
                <Typography variant="body2" style={{ margin: 20 }}>Select the subscription levels for which this activity should be disabled. Users on the selected tiers will no longer have access to this activity.</Typography>
                <FormGroup style={{ marginLeft: 25 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selected.length === options.length}
                                onChange={handleChange}
                                name="ALL"
                            />
                        }
                        label="All"
                        style={{ marginTop: 10, marginBottom: 10, borderRadius: 50 }}
                    />
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            control={
                                <Checkbox
                                    checked={selected.includes(option.value)}
                                    onChange={handleChange}
                                    name={option.value}
                                />
                            }
                            label={option.label}
                            style={{ marginTop: 10, marginBottom: 10 }}
                        />
                    ))}
                </FormGroup>

                <Box style={{ padding: 10 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                size='medium'
                                variant='outlined'
                                onClick={() => setOpen(false)}
                            >
                                CANCEL
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                size='medium'
                                onClick={handleConfirm}
                                variant="contained"
                                color="primary"
                            >
                                CONFIRM
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </Page>
    );
};

export default ActivityManagementListView;