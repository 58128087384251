import { Pagination } from "@material-ui/lab";
import axios from 'axios'
import { nodeCorpMongoApi, nodeGetApi, nodeServiceApi } from "../commonAxios";


const urlGenerator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}

//
export const getNotificationsLoadmore = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Notifications/Get_Admin', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Brands
export const paginationBrand = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Brand/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All pos Orders
export const paginationPosOrder = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/POS/Order_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Cities
export const paginationCities = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_City/Super_Admin_City_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All offer visitors
export const paginationOffers = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Offers_Visitor/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Inventory
export const paginationInventory = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Inventory/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};



//Get All Orders
export const paginationOrder = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/OrderAll_Get_Flow', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const retailOrder = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/retail-orders/get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//for Partner's Get

export const Partner_Get = async (formData = {}) => {
    return new Promise(async (resolve, reject) => {
        await nodeGetApi.post('/Partners/OnlineAndPhysical_Details_Get_Admin', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, data: [], error: err.message })
        })
    })
}


export const Partner_Adrss_Get = async (formData) => {
    return new Promise(async (resolve, reject) => {
        await nodeGetApi.post('/Partners/Partner_Address_Get_Admin', formData).then((res) => {
            if (res.data.Success_Response.Is_Data_Exist === "1") {
                resolve({ status: true, data: res.data.results })
            } else {
                resolve({ status: false, data: [] })
            }
        }).catch((err) => {
            resolve({ status: false, data: [], error: err.message })
        })
    })
}


export const Transaction_Orders_Get = (formData) => async (dispatch) => {
    try {
        const res = await nodeGetApi.post('/retail-orders/get', formData);
        // console.log(res, '................action........');
        if (res.data.Success_Response.Is_Data_Exist === "1") {
            return { status: true, data: res.data.results };
        } else {
            return { status: false, data: [] };
        }
    } catch (err) {
        return { status: false, data: [], error: err.message };
    }
};


export const NewgetInventory = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Inventory_V2/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All Transactions
export const paginationNewTransactions = (
    formData = {
        "search": '',
        "search_by_filter": 'All',
        "Records_Filter": "",
        "Order_Id": ""
    }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_V2/OrderAll_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};



//Get All NewOrders
export const NewOrderGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_V2/OrderAll_Get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All User Profiles
export const paginationUserProfiles = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/User/Profile_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Transactions
export const paginationTransactions = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Transactions/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const Transactions = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/transaction/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const UtilizedCouponGet = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/retail-orders/utilized-coupon-get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Product Masters
export const paginationProductMasters = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Products/Product_Master_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Products
export const paginationProducts = (
    formData = { Records_Filter: "FILTER", Is_Approved: "1" },
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Products/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Pending Products
export const paginationPendingProducts = (
    formData = { Records_Filter: "FILTER", Is_Approved: "0" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Products/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Product Partners
export const paginationProductPartners = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partners/All_Partner_Product_Address_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Pending Product Partners
export const paginationPendingProductPartners = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partners/All_Partner_Product_Address_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Deals
export const paginationDeals = (
    formData = { Records_Filter: "FILTER", Is_Approved: "1" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Deals/Deal_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Pending deals
export const paginationPendingDeals = (
    formData = { Records_Filter: "FILTER", Is_Approved: "0" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Deals/Deal_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Partner Service City
export const paginationPartnerServiceCity = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Shop_By_City/Partner_Service_City_GET', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Missing Cashback
export const paginationMissingCashback = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Tickets/Missing_Cashback_GET_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Categories
export const paginationCategories = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Categories/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All GiftVoucher
export const paginationGiftVoucher = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Gift_Voucher/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All GiftVoucher
export const paginationBanner = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Banner/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Roles
export const paginationRole = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Admin/Roles_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Coupons
export const paginationCoupons = (
    formData = { Records_Filter: "FILTER", Is_Approved: "1" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Coupons/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Pending Coupons
export const paginationPendingCoupons = (
    formData = { Records_Filter: "FILTER", Is_Approved: "0" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Coupons/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All GiftImage
export const paginationGiftImage = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Gift_Voucher/Image_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All Ads management
export const paginationAds = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Advertisement/PageAds_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All User Wallet management
export const paginationUserWallet = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/User_Wallet/Admin_Wallet_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All SubCategory management
export const paginationSubCategory = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Categories/Sub_Category_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}

// Get All GiftVendor management
export const paginationGiftVendor = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Gift_Voucher/Vendor_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}

// Get All Promotional management
export const paginationPromotional = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Promotional_Campaign/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}

// Get All content management
export const paginationContentManagement = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/ContentManagement/Static_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}

// Get All faq management
export const paginationFAQManagement = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/FAQ/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}

// Get All userpayments&settlements management
export const paginationUserpaymentsandsettlements = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/User/Payment_Settlement_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}


// Get All NewsEvents
export const paginationNewsEvents = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/News/News_Event_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All Email Template
export const paginationTemplates = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Templates/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All Settings Management
export const paginationSettings = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Setting/GET', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}


//Get All User Management
export const paginationUserManagement = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/User/Profile_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All store type Management
export const paginationStoreTypeManagement = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Store_Type/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Gift History
export const paginationGiftHistory = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Gift_History/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All city location
export const paginationCityLocation = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Location_For_City/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Shopbycity Coupon
export const paginationShopbycityCoupon = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Shop_By_City/Partner_Service_City_Coupons_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All store type linking Category
export const paginationStoreTypeLinking = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Store_Type_Linking/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All store type Partner
export const paginationStoreTypePartner = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Store_Type_Partner/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All Partner Services
export const paginationPartnerServices = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partner_Service/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};



// Get All Tickets
export const paginationTickets = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Tickets/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All Partnerserviceproduct
export const paginationPartnerserviceproduct = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Shop_By_City/Partner_Service_City_Products_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};
// Get All Comments
export const paginationComments = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Tickets/Comment_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All User Services Generic management
export const paginationUserServicesGenericManagement = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/User_Service/Generic_GET', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}

// Get All Testimonial management
export const paginationTestimonialManagement = (
    formData = { Records_Filter: "ALL" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/AppRatingsAndReviews/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}

//Get All Shopbycity Deal
export const paginationShopbycityDeal = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Shop_By_City/Partner_Service_City_Deals_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All User Wallet History
export const paginationUserWalletHistory = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/User_Wallet/Admin_Wallet_History_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Brand User reviews
export const paginationBrandUserReviewGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Reviews/Brand_Reviews_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Brand User reviews
export const paginationProductUserReviewGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Reviews/Product_Review_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All User Financial Transactions
export const paginationUserFinancialTransGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Payments/User_Financial_Transaction_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All NewsLetter
export const paginationNewsLetter = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/News/News_Letter_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};
// Get All PartnerEmployee
export const paginationPartnerEmployee = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_Partner_Employee/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};



// Get All Popup
export const paginationPopup = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Advertisement/Popup_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Product colors
export const paginationProductcolorsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Products/ColorVariantGet_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};
// Get All PartnerAddress
export const paginationPartnerAddress = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partners/Partner_Address_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Product Sizes
export const paginationProductSizes = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Products/SizeGet_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Wallet
export const paginationDeliveryWalletGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Delivery_Wallet/Wallet_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Wallet History
export const paginationDeliveryWalletHistoryGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Delivery_Wallet/Wallet_History_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Partner Payments
export const paginationPartnerPayments = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Payments/PPS_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Business Services Subtypes
export const paginationBusiServicesSubtypesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_Partner_Business_Type/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All user ride fares
export const paginationAllUserRideFaresGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_User_Ride_Vehicle_Fare/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Partners
export const paginationAllPartnersGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partners/OnlineAndPhysical_Details_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Related Products
export const paginationRelatedProducts = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Products/Related_Product_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results[0].Related_Products, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results[0].Related_Products);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

// Get All UserServicesGet
export const paginationUserServicesGet = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_User_Service_Request/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All corp faq's
export const paginationAllCorpCouponGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/Corp_Coupon/get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All corp faq's
export const paginationAllCorpFAQGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/FAQ/get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};
// Get All corp News and Events
export const paginationAllCorpNEGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('newsAndEvents/get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const paginationAllCorpCMSGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.get(urlGenerator('/Corp_CMS/get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All User Rides
export const paginationAllUserRidesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_User_Rides/User_Rides_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All B2B Orders
export const paginationAllB2BOrdersGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/B2B_Order/Get_By_ItemMaster_Product_Get_New', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Vehicles
export const paginationAllBiddingVehiclesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Bidding_Vehicle_Details/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Ride Images 
export const paginationAllBidRideImagesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Bidding_Ride_Images/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Vehicle Brands
export const paginationAllBidVehicleBrandsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Bidding_Rides_Vehicle_Brand/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All User Ride Biddings
export const paginationAllUserRideBiddingsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/User_Rides_Bidding/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Brand Vehicles
export const paginationAllBidBrandVehiclesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Bidding_Ride_Brand_Vehicle/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Ride Bidding Success Stories
export const paginationAllBidRideSuccessStoriesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Delivery_Bidding_Success_Stories/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Bidding Quotes 
export const paginationAllDeliveryBidQuotesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Bidding_Rides_Quotes/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Bidding comments 
export const paginationAllDeliveryBidCommentsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Bidding_Rides_Comments/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Partner Bidding Available Products 
export const paginationAllPartBidAvailProductsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Partner_Bidding_Available_Products', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Partner Bidding Quotes 
export const paginationAllPartnerBidQuotesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Partner_Bidding_Quotes', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Partner Bidding Comments 
export const paginationAllPartnerBidCommentsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Bidding_Product_Comments', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All B2B User Products Bidding
export const paginationAllB2BUserProductsBidGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/B2B_User_Products_Bidding/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All B2B User Products Bidding Quotes
export const paginationAllB2BUserProdBidQuotesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/B2B/Bidding_Quotes', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All B2B User Products Bidding Comments
export const paginationAllB2BUserProdBidCommentsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/B2B/Bidding_Comments', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All User Bidding Products
export const paginationAllUserBiddingProductsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/User_Products_Bidding', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Products Success Story
export const paginationAllBidProductsSuccessStoriesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Partner_Bidding_Success_Stories', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Products Category
export const paginationAllBiddingProductsCategoryGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Bidding_Products_Category/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Products
export const paginationAllBiddingProductsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Bidding_Products', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Services
export const paginationAllBiddingServicesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Bidding_Services', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};



//Get All Bidding Partner avail Services
export const paginationAllBidPartnerAvailServicesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Partner_Bidding_Available_Services', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All  User BiddingServices Get
export const paginationAllUserBidServicesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/User_Services_Bidding', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Bidding Services Success Story
export const paginationAllBidServicesSuccessStoriesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Partner_Bidding_Services_Success_Stories', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Partner Bidding Services Quotes
export const paginationAllBidPartnerServicesQuotesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Partner_Bidding_Service_Quotes', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Partner Bidding Service Quote Comments
export const paginationAllBidPartnerServiceQuoteCommentsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Bidding_Service_Comments', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};



//Get All super admin user get
export const paginationAdminUserGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/User_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All Delivery Comment Get
export const paginationDeliveryCommentGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Delivery_Ticket_Comment/Ticket_Comment_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All 
export const paginationDeliveryFaqGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Delivery_FAQ/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All 
export const paginationDeliveryPayment = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Payment_Settlement/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationDeliveryTicket = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Delivery_Ticket/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationCorporateTicket = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/corpTicket/get', pagination),
        formData
    ).then(response => {
        let data = response.data;

        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationGlobalCoupon = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Meepaisa_Coupon/meepaisa_coupon_get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationGlobalCouponHistory = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Meepaisa_Coupon/Global_Coupon_History_SuperAdmin_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationLocationCityGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Shop_by_Location_in_City/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationPartnerComment = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partner_Tickets/Comment_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationDeliveryAgent = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin_Partner_Delivery_Person/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationPartnerReview = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Reviews/Partner_Review_Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationPartnerWallet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partner_Wallet/Wallet_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationPartnerWalletHistory = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partner_Wallet/Wallet_History_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const paginationPermissionsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Super_Admin/Permissions_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};
export const paginationReferandEarn = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Refer_Earn/Get_Admin', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const paginationOrderShippingGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Order/Shipping_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationCorpPendingUsers = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/corpEnquiry/get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationCorpGifts = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/corpSAGiftHistory/get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationDeliveryAgentApproval = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Delivery_Registration/Get_Approval', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationPartnerTicket = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partner_Tickets/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const paginationRideVehicleType = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Rides_Vehicle_Types/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Bidding Vehicle Commission
export const paginationAllDeliBidVehicleCommissionGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Del_Bidding_Vehicle_Commission/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery  Vehicle Details
export const paginationAllDeliveryVehicleDetailsGet = (
    formData = {}, pagination = {},
    responseFunction) => async (dispatch) => {
        nodeGetApi.post(urlGenerator('/Super_Admin_Vehicle_Details/Get', pagination),
            formData,
        ).then(response => {
            let data = response.data;
            if (data.Success_Response.Is_Data_Exist === "0") {
                responseFunction(true, []);

            } else {
                try {
                    responseFunction(true, data.results, data.Pagination);
                } catch (error) {
                    responseFunction(true, data.results);
                }
            }
        }).catch(error => {
            responseFunction(false, error);

        });
    };

//Get All User Statistics
export const paginationAllUserStatisticsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/User_Statistics/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Delivery Partner Vehicle Details

export const paginationAllDeliveryPartnerVehicleDetailsGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Partner_Delivery_Vehicle_Details/Vehicle_Details_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });

};

// Get All contact us
export const paginationContactUs = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeGetApi.post(urlGenerator('/Contact_Us/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
}

//Get All Categories
export const paginationCorpCategories = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.get(urlGenerator('corpSAProductCategory/get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Pagination.totalResults === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Products
export const paginationProductsCorp = (
    formData = { Records_Filter: "FILTER", Is_Approved: "1" },
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('corp_SA_Product/get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.results.length <= 0) {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};



//New Service management

//Sevice category

export const paginationServicescategory = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeServiceApi.post(urlGenerator('/admin/services/get/', pagination),
        formData,

    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Services sub service


export const paginationsubcategoryServices = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeServiceApi.post(urlGenerator('/admin/sub_service/Get/', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Service varient

export const paginationvarientServices = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeServiceApi.post(urlGenerator('/admin/service_variant/Get/', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Service request

export const paginationrequestServices = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeServiceApi.post(urlGenerator('/admin/service_request/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Service Partner Management


export const paginationPartnermanagementServices = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeServiceApi.post(urlGenerator('/admin/partner_services/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//service bookings

export const paginationServicesBookings = (
    formData = {}, pagination = {}, responseFunction
) => async (dispatch) => {
    try {
        const paginationWithSorting = {
            ...pagination,
            sortBy: 'Created_Datetime',
            sortOrder: 'desc'
        };
        const response = await nodeServiceApi.post(
            urlGenerator('/admin/mps_service_bookings/Get', paginationWithSorting),
            formData
        );

        const data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            responseFunction(true, data.results, data.Pagination);
        }
    } catch (error) {
        responseFunction(false, error);
    }
};


//Get All Requested Products
export const paginationProductsRequestCorp = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('corpSAProductRequest/get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.results.length <= 0) {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Corporate orders
export const paginationCorpOrdersGet = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('Corp_SA_Product_Order/get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.results.length <= 0) {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

//Get All Corporate order Info
export const paginationCorpOrderInfoGet = (
    formData = {},
    pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('Corp_SA_Product_Order/detailsGet', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.results.length <= 0) {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationcitycategoryServices = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeServiceApi.post(urlGenerator('/admin/service_variant_city_charge/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        console.log(response.data)

        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};



export const paginationServicesBanners = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeServiceApi.post(urlGenerator('/admin/mps_banners/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


//Get All corp award's
export const paginationAllCorpAWARDGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/Award_Template/get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationAllCorpUsers = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/TotalCorpUsers/get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationAllCorpPopupGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/Corp_User_Policy/get', pagination),
        formData
    ).then(response => {
        let data = response.data;
        if (response.status < 200 || response.status >= 300) {
            return responseFunction(true, []);
        }
        try {
            responseFunction(true, data.results, data.Pagination);
        } catch (error) {
            responseFunction(true, data.results);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const paginationCorpCouponHistoryGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/Corp_Coupon/history', pagination), formData)
        .then(response => {
            let data = response.data;
            if (response.status < 200 || response.status >= 300) {
                return responseFunction(true, []);
            }
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }).catch(error => {
            responseFunction(false, error);
        });
};

export const paginationCorpCouponEmployeesGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/Corp_Coupon/corpEmpsGetPagination', pagination), formData)
        .then(response => {
            let data = response.data;
            if (response.status < 200 || response.status >= 300) {
                return responseFunction(true, []);
            }
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }).catch(error => {
            responseFunction(false, error);
        });
};

export const paginationAllCorpEmpHappinessSurveyGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/corpHappinessIndexSurvey/get', pagination), formData)
        .then(response => {
            let data = response.data;
            if (response.status < 200 || response.status >= 300) {
                return responseFunction(true, []);
            }
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }).catch(error => {
            responseFunction(false, error);
        });
};

export const paginationAllCorpEmpActivityGet = (
    formData = {}, pagination = {},
    responseFunction
) => async (dispatch) => {
    nodeCorpMongoApi.post(urlGenerator('/corpActivityManagement/get', pagination), formData)
        .then(response => {
            let data = response.data;
            if (response.status < 200 || response.status >= 300) {
                return responseFunction(true, []);
            }
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }).catch(error => {
            responseFunction(false, error);
        });
};