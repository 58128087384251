import { combineReducers } from 'redux';
import brandReucer from './brandReducer';
import categoryReducer from './categoryReducer';
import partnerReducer from './partnerReducer';
import subcategoryReducer from './subcategoryReducer';
import dealReducer from './dealReducer';
import couponReducer from './couponReducer';
import authenticationReducer from './authenticationReducer';
import productReducer from './productReducer';
import rproductReducer from './relatedProductReducer';
import userprofileReducer from './userprofileReducer';
import bannerReducer from './bannerReducer';
import ticketingReducer from './ticketingReducer';
import transactionhistoryReducer from './transactionhistoryReducer';
import walletReducer from './walletReducer';
import billreceiptReducer from './missingcashbackReducer';
import referandearnReducer from './referandearnReducer';
import productpartnerReducer from './productpartnerReducer';
import partnerreviewtableReducer from './partnerreviewtableReducer'
import brandreviewReduser from './brandreviewReduser'
import newseventsReducer from './newseventsReducer';
import userreviewReducer from './userreviewReducer';
import orderReducer from './orderReducer';
import partneruserreviewReducer from './partneruserreviewReducer';
import branduserreviewReducer from './branduserreviewReducer';
import offersvisitorReducer from './offersvisitorReducer';
import adsReducer from './adsReducer';
import productrevirewReducer from './reviewsandratingReducer'
import FAQReducer from './FAQReducer'
import newsletterReducer from './newsletterReducer';
import inventoryReducer from './inventoryReducer';
import templateReducer from './templateReducer';
import userpaymentsettlementsReducer from './userpaymentsettlementsReducer';
import partnerpaymentsettlementsReducer from './partnerpaymentsettlementsReducer';
import promotionalReducer from './promotionalReducer';
import partneraddressReducer from './partneraddressReducer';
import shippingReducer from './shippingReducer';
import giftReducer from './giftReducer';
import privacypolicyTermsConditionsReducer from './privacypolicyTermsConditionsReducer';
import popupReducer from './popupReducer';
import dashboardReducers from './reports/dashboardReducers';
import userReducer from './userReducer';
import productmasterReducer from './productmasterReducer';
import productcolorvariantReducer from './productcolorvariantReducer';
import productsizeReducer from './productsizeReducer';
import giftvendorReducer from './giftvendorReducer';
import giftimageReducer from './giftimageReducer';
import settingReducer from './settingReducer';
import sbcmCityReducer from './sbcmCityReducer';
import citypartnerReducer from './citypartnerReducer';
import citypartnercouponReducer from './citypartnercouponReducer';
import citypartnerdealReducer from './citypartnerdealReducer';
import sbcmProductReducer from './sbcmProductReducer';
import wallethistoryReducer from './wallethistoryReducer';
import usergifthistoryReducer from './usergifthistoryReducer';
import testimonialReducer from './testimonialReducer';
import notificationReducer from './notificationReducer';
import deliveryAgentReducer from './deliveryAgentReducer';
import deliverycmspageReducer from './deliverycmspageReducer';
import deliveryFAQReducer from './deliveryFAQReducer';
import deliverydashboardReducer from './delivery-reports/deliverydashboardReducer';
import corporatefaqReducer from './corporatefaqReducer';
import esimaincenterdateandcountReducer from './esimaincenterReducer'
import contentdynamicReducer from './contentdynamicReducer';
import orderdynamicReducer from './orderdynamicReducer';
import promotiondynamicReducer from './promotiondynamicReducer';
import settingsdynamicReducer from './settingsdynamicReducer';
import corporatedynamicReducer from './corporatedynamicReducer';
import deliveryReducer from './deliveryReducer';
import commentReducer from './commentReducer';
import deliverypaymentsettlementsReducer from './deliverypaymentsettlementsReducer';
import deliveryticketReducres from './deliveryticketReducres';
import deliverycommentReducers from './deliverycommentReducers';
import partnerdeliveryagentsReducer from './partnerdeliveryagentsReducer';
import partneremployeeReducer from './partneremployeeReducer';
import storetypeReducer from './storetypeReducer';
import storetypelinkingReducer from './storetypelinkingReducer';
import userfinancialtransactionsReducer from './userfinancialtransactionsReducer';
import roleReducer from './roleReducer';
import employeeprofileReducer from './employeeprofileReducer';
import mycompanyprofileReducer from './mycompanyprofileReducer';
import citylocationReducer from './citylocationReducer';
import partnercitylocationReducer from './partnercitylocationReducer';
import companysettingsdateandcountReducer from './companysettingsReducer';
import storedateandtimeReducer from './storedynamicdateandtimeReducer';
import partnerbankdetailsReducer from './partnerbankdetailsReducer';
import storetypepartnerlinkingReducer from './storetypepartnerlinkingReducer';
import b2borderReducer from './b2borderReducer';
import userridesReducer from './userridesReducer';
import servicesReducer from './servicesReducer';
import partnerserviceReducer from './partnerserviceReducer';
import userridefaresReducer from './userridefaresReducer';
import servicepartnercityReducer from './servicepartnercityReducer';
import deliverywalletReducer from './deliverywalletReducer';
import partnerwalletReducer from './partnerwalletReducer';
import partnerwallethistoryReducer from './partnerwallethistoryReducer';
import ridesdynamicdateandcountReducer from './ridesdynamicdateandcountReducer';
import servicesdynamicdateandcountReducer from './servicesdynamicdateandcountReducer';
import partnercommentReducer from './partnercommentReducer';
import partnerticketingReducer from './partnerticketingReducer';
import partnerdynamicdateandcountReducer from './partnerdynamicdateandcountReducer';
import deliveryvehicledetailsReducer from './deliveryvehicledetailsReducer';
import bussinesssubtypeReducer from './bussinesssubtypeReducer';
import usermaincenterdateandcountReducer from './userdynamicdateandcountReducer';
import razorpaypaymentReducer from './razorpaypaymentReducer';
import razorpaytransactionReducer from './razorpaytransactionReducer';
import globalcouponReducer from './globalcouponReducer';
import partnervehicledetailsReducer from './partnervehicledetailsReducer';
import subscriptionuserReducer from './subscriptionuserReducer';
import subscriptionuserproductReducer from './subscriptionuserproductReducer';
import subscriptionpackagesReducer from './subscriptionpackagesReducer';
import subspacklocationsReducer from './subspacklocationsReducer';
import ridevehicletypesReducer from './ridevehicletypesReducer';
import permissionsReducer from './permissionsReducer';
import razorpayReducer from './razorpayReducer';
import dynamicrolepermissionReducer from './dynamicrolepermissionReducer';
import userstatisticsReducer from './userstatisticsReducer';
import biddingridevehiclesReducer from './biddingridevehiclesReducer';
import biddingridesuccessstoriesReducer from './biddingridesuccessstoriesReducer';
import bidpartneravailproductsReducer from './bidpartneravailproductsReducer';
import partnerbiddingquotesReducer from './partnerbiddingquotesReducer';
import partnerbiddingcommentsReducer from './partnerbiddingcommentsReducer';
import biddingvehiclebrandsReducer from './biddingvehiclebrandsReducer';
import userridebiddingsReducer from './userridebiddingsReducer';
import deliverybiddingquotesReducer from './deliverybiddingquotesReducer';
import deliverybiddingcommentsReducer from './deliverybiddingcommentsReducer';
import biddingridebrandvehiclesReducer from './biddingridebrandvehiclesReducer';
import b2buserproductsbiddingReducer from './b2buserproductsbiddingReducer';
import biddinguserproductsReducer from './biddinguserproductsReducer';
import biddingproductssucessstoriesReducer from './biddingproductssucessstoriesReducer';
import biddingproductsReducer from './biddingproductsReducer';
import biddingproductscategoryReducer from './biddingproductscategoryReducer';
import biddingservicesReducer from './biddingservicesReducer';
import biddingservicesuccessstoriesReducer from './biddingservicesuccessstoriesReducer';
import biddingpartneravailableserviceReducer from './biddingpartneravailableserviceReducer';
import biddinguserserviceReducer from './biddinguserserviceReducer';
import partnerbiddingservicequoteReducer from './partnerbiddingservicequoteReducer';
import partnerbiddingservicequotecommentsReducer from './partnerbiddingservicequotecommentsReducer';
import b2buserprodbidquotesReducer from './b2buserprodbidquotesReducer';
import b2buserprodbidcommentsReducer from './b2buserprodbidcommentsReducer';
import biddingdateandcountReducer from './biddingdateandcountReducer';
import deliverybiddingvehiclecommissionReducer from './deliverybiddingvehiclecommissionReducer';
import meepaisaproductcomboReducer from './meepaisaproductcomboReducer';
import meepaisazoneReducer from './meepaisazoneReducer';
import meepaisazonethemeReducer from './meepaisazonethemeReducer';
import meepaisathemeReducer from './meepaisathemeReducer';
import meepaisazonethemeproductsReducer from './meepaisazonethemeproductsReducer';
import corporatenewsandeventsReducer from './corporatenewsandeventsReducer';
import corporateusersReducer from './corporateusersReducer';
import corporateticketReducer from './corporateticketReducer';
import corporatecouponReducer from './corporatecouponReducer';
import corpcategoryReducer from './corpcategoryReducer';
import corpproductReducer from './corpproductReducer';
import Sevicecategory from './Sevicecategoryreducer';
import servicespartnermanagementReducer from './servicespartnermanagementReducer';
import servicesubcategoryreducer from './servicesubcategoryreducer';
import corporderReducer from './corporderReducer';
import ServiceBannersReducer from './ServiceBannersReducer';
import corporateawardReducer from './corporateawardReducer';
import corporatepopupReducer from './corporatepopupReducer';
import corpemphappinesssurveyReducer from './corpemphappinesssurveyReducer';
import corpempactivitymanagementReducer from './corpempactivitymanagementReducer';

export default combineReducers({
  newsevents: newseventsReducer,
  newsletters: newsletterReducer,
  auth: authenticationReducer,
  brand: brandReucer,
  wallethistory: wallethistoryReducer,
  setting: settingReducer,
  category: categoryReducer,
  partner: partnerReducer,
  subcategory: subcategoryReducer,
  deals: dealReducer,
  orders: orderReducer,
  coupon: couponReducer,
  product: productReducer,
  rproduct: rproductReducer,
  userprofile: userprofileReducer,
  banner: bannerReducer,
  tickets: ticketingReducer,
  privacypolicytermscondition: privacypolicyTermsConditionsReducer,
  transaction: transactionhistoryReducer,
  bill: billreceiptReducer,
  wallet: walletReducer,
  user: userReducer,
  referandearn: referandearnReducer,
  productPartner: productpartnerReducer,
  userreview: userreviewReducer,
  partnerreview: partnerreviewtableReducer,
  brandreview: brandreviewReduser,
  partneruserreview: partneruserreviewReducer,
  branduserreview: branduserreviewReducer,
  offer: offersvisitorReducer,
  pagead: adsReducer,
  faq: FAQReducer,
  deliveryfaq: deliveryFAQReducer,
  productreview: productrevirewReducer,
  inventory: inventoryReducer,
  userpayment: userpaymentsettlementsReducer,
  partnerpayment: partnerpaymentsettlementsReducer,
  template: templateReducer,
  promotional: promotionalReducer,
  popup: popupReducer,
  partneraddress: partneraddressReducer,
  shippings: shippingReducer,
  gift: giftReducer,
  usergifthistory: usergifthistoryReducer,
  dash: dashboardReducers,
  productmaster: productmasterReducer,
  productcolorvariant: productcolorvariantReducer,
  productsize: productsizeReducer,
  giftvendor: giftvendorReducer,
  giftimage: giftimageReducer,
  sbcmCity: sbcmCityReducer,
  sbcmProduct: sbcmProductReducer,
  citypartner: citypartnerReducer,
  citypartnercoupon: citypartnercouponReducer,
  citypartnerdeal: citypartnerdealReducer,
  testimonials: testimonialReducer,
  AllNotifications: notificationReducer,
  delivery: deliveryAgentReducer,
  contentmanagement: deliverycmspageReducer,
  deliverydashboard: deliverydashboardReducer,
  corporatefaq: corporatefaqReducer,
  esimaincenterdateandcountReducer: esimaincenterdateandcountReducer,
  contentdateandcountReducer: contentdynamicReducer,
  orderdateandcountReducer: orderdynamicReducer,
  promotiondateandcountReducer: promotiondynamicReducer,
  settingdateandcountReducer: settingsdynamicReducer,
  corporatedateandcountReducer: corporatedynamicReducer,
  deliverydateandcountReducer: deliveryReducer,
  comments: commentReducer,
  deliverypayment: deliverypaymentsettlementsReducer,
  deliverytickets: deliveryticketReducres,
  deliverycomments: deliverycommentReducers,
  partnerdeliveryagent: partnerdeliveryagentsReducer,
  partneremployee: partneremployeeReducer,
  storetype: storetypeReducer,
  storetypelink: storetypelinkingReducer,
  storetypepartnerlink: storetypepartnerlinkingReducer,
  userfinancialtransaction: userfinancialtransactionsReducer,
  role: roleReducer,
  user: employeeprofileReducer,
  myprofiles: mycompanyprofileReducer,
  citylocation: citylocationReducer,
  partnercitylocation: partnercitylocationReducer,
  companysettingsdateandcountReducer: companysettingsdateandcountReducer,
  storedateandtimeReducer: storedateandtimeReducer,
  partnerbank: partnerbankdetailsReducer,
  b2borders: b2borderReducer,
  userrides: userridesReducer,
  service: servicesReducer,
  partnerservice: partnerserviceReducer,
  userrideFares: userridefaresReducer,
  genericservice: servicepartnercityReducer,
  deliverywallet: deliverywalletReducer,
  partnerwallet: partnerwalletReducer,
  partnerwallethistory: partnerwallethistoryReducer,
  ridesdynamicdateandcountReducer: ridesdynamicdateandcountReducer,
  servicesdynamicdateandcountReducer: servicesdynamicdateandcountReducer,
  partnercomments: partnercommentReducer,
  partnertickets: partnerticketingReducer,
  partnerdynamicdateandcountReducer: partnerdynamicdateandcountReducer,
  deliveryvehicledetail: deliveryvehicledetailsReducer,
  bussinessSubtype: bussinesssubtypeReducer,
  usermaincenterdateandcountReducer: usermaincenterdateandcountReducer,
  razorpaypayment: razorpaypaymentReducer,
  razorpaytransaction: razorpaytransactionReducer,
  globalcoupon: globalcouponReducer,
  partnervehicledetail: partnervehicledetailsReducer,
  subscription: subscriptionuserReducer,
  subscriptionproduct: subscriptionuserproductReducer,
  subscriptionpackages: subscriptionpackagesReducer,
  subspacklocations: subspacklocationsReducer,
  ridevehicletypes: ridevehicletypesReducer,
  permission: permissionsReducer,
  razorpaytransaction: razorpaytransactionReducer,
  razorpay: razorpayReducer,
  rolepermission: dynamicrolepermissionReducer,
  userstatistic: userstatisticsReducer,
  biddingvehicles: biddingridevehiclesReducer,
  bidrideSuccessStories: biddingridesuccessstoriesReducer,
  partnerbidavailproducts: bidpartneravailproductsReducer,
  partnerbiddingquotes: partnerbiddingquotesReducer,
  partnerbiddingcomment: partnerbiddingcommentsReducer,
  bidvehiclebrands: biddingvehiclebrandsReducer,
  userridebidding: userridebiddingsReducer,
  deliverybiddingquotes: deliverybiddingquotesReducer,
  deliverybiddingcomment: deliverybiddingcommentsReducer,
  bidridebrandvehicles: biddingridebrandvehiclesReducer,
  b2buserproductsbid: b2buserproductsbiddingReducer,
  biddinguserproducts: biddinguserproductsReducer,
  bidproductsSuccessStories: biddingproductssucessstoriesReducer,
  biddingproductscategory: biddingproductscategoryReducer,
  biddingproducts: biddingproductsReducer,
  biddingservices: biddingservicesReducer,
  bidservicesuccessstory: biddingservicesuccessstoriesReducer,
  biddingpartneravailableservices: biddingpartneravailableserviceReducer,
  biddinguserservices: biddinguserserviceReducer,
  partnerbiddingservicequotes: partnerbiddingservicequoteReducer,
  biddingservicecommentquote: partnerbiddingservicequotecommentsReducer,
  b2buserprodbidquote: b2buserprodbidquotesReducer,
  b2buserprodbidcomment: b2buserprodbidcommentsReducer,
  biddingdateandcount: biddingdateandcountReducer,
  delibidvehicommission: deliverybiddingvehiclecommissionReducer,
  meepaisaproductcombo: meepaisaproductcomboReducer,
  zone: meepaisazoneReducer,
  zonetheme: meepaisazonethemeReducer,
  theme: meepaisathemeReducer,
  zonethemeproduct: meepaisazonethemeproductsReducer,
  corpnewsandevents: corporatenewsandeventsReducer,
  corpusers: corporateusersReducer,
  corptickets: corporateticketReducer,
  corpcoupons: corporatecouponReducer,
  corpcategoy: corpcategoryReducer,
  corpproduct: corpproductReducer,
  categories: Sevicecategory,
  Serviceparters: servicespartnermanagementReducer,
  servicesubcategory: servicesubcategoryreducer,
  corporder: corporderReducer,
  servicebanner: ServiceBannersReducer,
  corpaward: corporateawardReducer,
  corppopup: corporatepopupReducer,
  corpemphappinesssurvey: corpemphappinesssurveyReducer,
  corpempactivity: corpempactivitymanagementReducer
});  