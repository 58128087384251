import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PuzzleForm from './PuzzleForm';
import QuizForm from './QuizForm';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Typography } from '@mui/material';
import { uploadPuzzleGameImage } from 'src/store/actions/corpempactivitymanagementAction';
const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 200,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const GamesOption = [
  { value: '', label: '-- Please Select --' },
  { value: 'Puzzle', label: 'Puzzle Game' },
  { value: 'Quiz', label: 'Quiz' }
];

const statusOption = [
  { value: '', label: '-- Please Select --' },
  { value: 'Active', label: 'Active' },
  { value: 'In-Active', label: 'In-Active' }
];

const AddGames = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedGame, setSelectedGame] = useState('');
  const [formData, setFormData] = useState({
    Category: '',
    Activity_Name: '',
    Status: '',
    Description: '',
    Activity_Image: ''
  });
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'Category') setSelectedGame(value);
  };

  const handleImage = e => {
    const file = e.target.files[0];
    if (file) {
      setImage({ preview: URL.createObjectURL(file), raw: file });
      const data = new FormData();
      data.append('file', file);
      dispatch(
        uploadPuzzleGameImage(data, res => {
          if (res) setFormData({ ...formData, Activity_Image: res.path });
        })
      );
    }
  };

  const handleRemoveImage = () => {
    setImage({ preview: '', raw: '' });
    setFormData({ ...formData, Activity_Image: '' });
  };

  const onEditorStateChange = editorState => {
    setEditorState(editorState);
    const descriptionHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFormData({ ...formData, Description: descriptionHtml });
  };
  const isEmptyHTML = html => {
    const strippedHtml = html.replace(/<[^>]+>/g, '').trim(); // Remove all HTML tags
    return strippedHtml === ''; // Check if only empty tags exist
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Category"
                    name="Category"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={formData.Category}
                    onChange={handleInputChange}
                    variant="outlined"
                    helperText={
                      formData.Category === '' ? 'please enter value' : null
                    }
                    FormHelperTextProps={{
                      style: { color: formData.Category === '' ? 'red' : null }
                    }}
                  >
                    {GamesOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Activity Name"
                    name="Activity_Name"
                    helperText={
                      formData.Activity_Name === ''
                        ? 'please enter value'
                        : null
                    }
                    value={formData.Activity_Name}
                    FormHelperTextProps={{
                      style: {
                        color: formData.Activity_Name === '' ? 'red' : null
                      }
                    }}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="Status"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={formData.Status}
                    onChange={handleInputChange}
                    variant="outlined"
                    helperText={
                      formData.Status === '' ? 'please enter value' : null
                    }
                    FormHelperTextProps={{
                      style: { color: formData.Status === '' ? 'red' : null }
                    }}
                  >
                    {statusOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.description}>
                    <EditorContainer
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                    />
                  </Box>
                  {formData.Description.replace(/(<([^>]+)>)/gi, '').trim() ===
                    '' && (
                    <Typography style={{ color: 'red', marginTop: '5px' }}>
                      please enter value
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card
                style={{
                  border: `2px dashed blue`,
                  textAlign: 'center',
                  background: 'rgba(33, 150, 243, 0.08)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: '516px',
                  height: '270px',
                  minWidth: '360px'
                }}
              >
                <CardContent>
                  <Box display="flex" justifyContent="center">
                    {image.preview ? (
                      <Avatar
                        className={classes.images}
                        alt="Brand Upload"
                        src={image.preview}
                        variant="square"
                      />
                    ) : (
                      <>
                        <Avatar
                          className={classes.images}
                          alt="Brand Upload"
                          src="/static/images/image-placeholder.png"
                          variant="square"
                        />
                      </>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {image.preview ? (
                      <Button
                        style={{
                          fontSize: '10',
                          color: ' #425A70',
                          textTransform: 'none'
                        }}
                        onClick={handleRemoveImage}
                      >
                        Remove Picture
                      </Button>
                    ) : (
                      <input
                        style={{ display: 'block', width: '80%' }}
                        id="upload-photo"
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={e => handleImage(e)}
                      />
                    )}
                    <br />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {selectedGame === 'Puzzle' && (
        <PuzzleForm pagedata={formData} setFieldValue={setFormData} />
      )}
      {selectedGame === 'Quiz' && <QuizForm />}
    </>
  );
};

AddGames.propTypes = {
  className: PropTypes.string
};

export default AddGames;
