
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Chip,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { useLocation } from 'react-router-dom';
import { ConnectedFocusError } from "focus-formik-error";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import { CircularProgress } from '@mui/material';
import EditorContainer from 'src/components/RichTextEditor/RichTextEditor';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getMRP, getAllFilterCategories, getAllProductlabels, getsingleProductDetails, updateProductAction } from 'src/store/actions/corporateproductAction';
import { uploadCategoryImage } from 'src/store/actions/corpcategoryAction';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { toast } from 'react-toastify';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const useStyles = makeStyles(() => ({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  description: {
    width: '100%',
    height: '20rem',
    padding: '1rem',
    overflow: 'auto',
    border: '1px solid grey'
  }
}));

const EditProduct = ({ className, ...rest }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = location;
  const classes = useStyles();

  // const [mediaimages, setMediaImages] = useState({
  //   1: null,
  //   2: null,
  //   3: null,
  //   4: null,
  //   5: null,
  //   6: null,
  // });
  const [mediaimages, setMediaImages] = useState({
    1: { Product_Image_Id: null, Image_Path: null },
    2: { Product_Image_Id: null, Image_Path: null },
    3: { Product_Image_Id: null, Image_Path: null },
    4: { Product_Image_Id: null, Image_Path: null },
    5: { Product_Image_Id: null, Image_Path: null },
    6: { Product_Image_Id: null, Image_Path: null },
  });

  // const imageUrls = Object.values(mediaimages).filter(url => url !== null);
  const imageUrls = Object.values(mediaimages).filter(image => image.Image_Path !== null);

  const [currentTaxRange, setCurrentTaxRange] = useState({ Product_Color_Name: '', Product_Color_Id: '', Is_Thumbnail: false, Product_Images: [], Size: [], Product_Price_On_Color_Size_Id: '', Cost_Price: "", Discount_Value: "", Platform_Fee: "", Selling_Price: "" });
  const [discountopen, setDiscountOpen] = useState(false);
  const [productNameCheck, setProductName] = useState("");
  const [brandNameCheck, setBrandName] = useState("");
  const [productData, setProductDatils] = useState();
  const [descError, setDescError] = useState()
  const [shortdescError, setShortDescError] = useState()
  const [clothingopen, setClothingOpen] = useState(false);
  const [isUserCustomizable, setIsUserCustomizable] = useState(false);

  useEffect(() => {
    if (productData?.Is_Clothing !== undefined) {
      setClothingOpen(productData.Is_Clothing);
    }
    if (productData?.Is_User_Customizable !== undefined) {
      setIsUserCustomizable(productData.Is_User_Customizable);
    }
  }, [productData]);

  // componentDidMount
  useEffect(() => {
    if (state._id) {
      const formData = {
        "Product_Id": state._id
      }
      dispatch(getAllFilterCategories("", callBackCategoriesdata))
      dispatch(getsingleProductDetails(formData, callBackSingleProductDetails))
      dispatch(getAllProductlabels(callBackProductlabelOption))
    }
  }, [state._id]);

  useEffect(() => {
    handleChangeImage()
  }, [mediaimages]);

  const isSubmited = useSelector(state => state.corpproduct.productUpdate.isLoading);
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const [units, setUnits] = useState(productData?.Product_Available_Sizes ? productData.Product_Available_Sizes : []);
  const unitIds = units.map(unit => unit.Corp_Product_Generic_Size_Id);
  const [unitType, setUnitType] = useState("");
  const [imagesforinitialvalues, setImagesForInitialValues] = useState(productData?.Product_Customizable_Images?.length > 0 ? productData?.Product_Customizable_Images : []);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [sizearray, setArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });

  const transformProductData = (product) => {
    const result = [];
    product.Corp_Product_Colors.forEach(color => {
      color.Product_Price_On_Color_Size.forEach(price => {
        result.push({
          BrandName: product.Brand_Name,
          ProductName: product.Product_Name,
          Product_Color_Name: color.Product_Color_Name,
          Product_Color_Id: color._id,
          // Product_Images: color.Product_Images,
          Product_Images: color.Product_Images.map(image => ({
            Product_Image_Id: image.Product_Image_Id,
            Image_Path: image.Image_Path
          })),
          Is_Thumbnail: color.Is_Thumbnail,
          Cost_Price: price.Cost_Price,
          Discount_Value: price.Discount_Value,
          Platform_Fee: price.Platform_Fee,
          Selling_Price: price.Selling_Price,
          Size: price.Size,
          Product_Price_On_Color_Size_Id: price._id,
          // Product_color_id: price._id
        });
      });
    });
    return result;
  };
  const decodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  const removePTags = (htmlContent) => {
    if (!htmlContent) return '';
    const decodedContent = decodeHTMLEntities(htmlContent);
    return decodedContent.replace(/<\/?p[^>]*>/g, '');
  };

  const [validation, setValidation] = useState();
  const handleClickForCopy = () => {
    const newPriceDetails = {
      Size: currentTaxRange.Size,
      Cost_Price: parseFloat(currentTaxRange.Cost_Price),
      Selling_Price: parseFloat(currentTaxRange.Selling_Price),
      Platform_Fee: parseFloat(currentTaxRange.Platform_Fee),
      Discount_Value: parseFloat(currentTaxRange.Discount_Value),
      Is_Thumbnail: currentTaxRange.Is_Thumbnail,
      // Product_Images: currentTaxRange.Product_Images,
      Product_Images: currentTaxRange.Product_Images.map(img => ({
        Product_Image_Id: img.Product_Image_Id || null,
        Image_Path: img.Image_Path
      })),
      Product_Color_Name: currentTaxRange.Product_Color_Name,
      Product_Color_Id: currentTaxRange.Product_Color_Id ? currentTaxRange.Product_Color_Id : null,
      Product_Price_On_Color_Size_Id: currentTaxRange.Product_Price_On_Color_Size_Id ? currentTaxRange.Product_Price_On_Color_Size_Id : null,
      BrandName: brandNameCheck,
      ProductName: productNameCheck
    };
    if (!productNameCheck
      || !brandNameCheck
      || !currentTaxRange.Size
      || !currentTaxRange.Cost_Price
      || !currentTaxRange.Selling_Price
      || !currentTaxRange.Platform_Fee
      || currentTaxRange.Cost_Price < 0
      || currentTaxRange.Selling_Price < 0
      || currentTaxRange.Platform_Fee < 0
      // || !currentTaxRange.Product_Images
      || currentTaxRange.Product_Images.length === 0
      || !currentTaxRange.Product_Color_Name
    ) {
      const validationErrors = {};
      if (!currentTaxRange.Cost_Price) {
        validationErrors.Cost_Price = "Cost Price is required";
      }
      if (currentTaxRange.Cost_Price < 0) {
        validationErrors.Cost_Price = "Cost Price must be positive";
      }
      if (!currentTaxRange.Selling_Price) {
        validationErrors.Selling_Price = "Selling Price is required";
      }
      if (currentTaxRange.Selling_Price < 0) {
        validationErrors.Selling_Price = "Selling Price must be positive";
      }
      if (!currentTaxRange.Platform_Fee) {
        validationErrors.Platform_Fee = "Platform Fee is required";
      }
      if (currentTaxRange.Platform_Fee < 0) {
        validationErrors.Platform_Fee = "Platform Fee must be positive";
      }
      // if (!currentTaxRange.Product_Images.length > 0) {
      //   validationErrors.Product_Images = "Product Images is required";
      // }
      if (currentTaxRange.Product_Images.length === 0) {
        validationErrors.Product_Images = "Product Images are required";
      }
      if (!currentTaxRange.Product_Color_Name) {
        validationErrors.Product_Color_Name = "Product Color Name is required";
      }
      if (!currentTaxRange.Size.length > 0) {
        validationErrors.Size = "Product Size is required";
      }
      if (!brandNameCheck) {
        validationErrors.Brand_Name = "Brand Name is required";
      }
      if (!productNameCheck) {
        validationErrors.Product_Name = "Product Name is required";
      }
      if (Object.keys(validationErrors).length > 0) {
        setValidation({ ...validation, ...validationErrors });
      }
      return;
    }

    const isDuplicate = sizearray.find(item =>
      item.Size === newPriceDetails.Size &&
        item.Cost_Price === newPriceDetails.Cost_Price &&
        item.Selling_Price === newPriceDetails.Selling_Price &&
        item.Platform_Fee === newPriceDetails.Platform_Fee &&
        item.Discount_Value === newPriceDetails.Discount_Value &&
        item.Is_Thumbnail === newPriceDetails.Is_Thumbnail &&
        JSON.stringify(item.Product_Images) === JSON.stringify(newPriceDetails.Product_Images) &&
        item.Product_Color_Name === newPriceDetails.Product_Color_Name &&
        item.Product_Color_Id === newPriceDetails.Product_Color_Id ? newPriceDetails.Product_Color_Id : null &&
          item.Product_Price_On_Color_Size_Id === newPriceDetails.Product_Price_On_Color_Size_Id ? newPriceDetails.Product_Price_On_Color_Size_Id : null
    );
    if (!isDuplicate) {
      if (editIndex !== null && editIndex >= 0) {
        setArray(prevArray => {
          const updatedArray = [...prevArray];
          updatedArray[editIndex] = newPriceDetails;
          return updatedArray;
        });
        setEditIndex(null);
      } else {
        setArray(prevArray => [...prevArray, newPriceDetails]);
      }
    } else {
      console.log('Duplicate entry detected');
    }
  };
  const transformData = (data) => {
    const groupedData = {};
    data.forEach(item => {
      const { Product_Color_Id, Product_Color_Name, Product_Images, Is_Thumbnail, Size, Cost_Price, Selling_Price, Platform_Fee, Discount_Value, Product_Price_On_Color_Size_Id } = item;
      if (!groupedData[Product_Color_Name]) {
        groupedData[Product_Color_Name] = {
          Product_Color_Name,
          Is_Thumbnail,
          Product_Images,
          Product_Color_Id,
          Product_Price_On_Color_Size: []
        };
      }
      groupedData[Product_Color_Name].Product_Price_On_Color_Size.push({
        Size,
        Cost_Price,
        Selling_Price,
        Platform_Fee,
        Discount_Value,
        Product_Price_On_Color_Size_Id
      });
    });
    return Object.values(groupedData);
  };
  const handleClickForAddMore = () => {
    const newPriceDetails = {
      Size: currentTaxRange.Size,
      Cost_Price: parseFloat(currentTaxRange.Cost_Price),
      Selling_Price: parseFloat(currentTaxRange.Selling_Price),
      Platform_Fee: parseFloat(currentTaxRange.Platform_Fee),
      Discount_Value: parseFloat(currentTaxRange.Discount_Value),
      Is_Thumbnail: currentTaxRange.Is_Thumbnail,
      // Product_Images: currentTaxRange.Product_Images,
      Product_Images: currentTaxRange.Product_Images.map(img => ({
        Product_Image_Id: img.Product_Image_Id || null,
        Image_Path: img.Image_Path
      })),
      Product_Color_Name: currentTaxRange.Product_Color_Name,
      Product_Color_Id: currentTaxRange.Product_Color_Id ? currentTaxRange.Product_Color_Id : null,
      Product_Price_On_Color_Size_Id: currentTaxRange.Product_Price_On_Color_Size_Id ? currentTaxRange.Product_Price_On_Color_Size_Id : null,
      BrandName: brandNameCheck,
      ProductName: productNameCheck
    };
    if (!productNameCheck
      || !brandNameCheck
      || !currentTaxRange.Size
      || !currentTaxRange.Cost_Price
      || !currentTaxRange.Selling_Price
      || currentTaxRange.Platform_Fee === ""
      || currentTaxRange.Platform_Fee === null
      || currentTaxRange.Platform_Fee === undefined
      || currentTaxRange.Cost_Price < 0
      || currentTaxRange.Selling_Price < 0
      || currentTaxRange.Platform_Fee < 0
      // || !currentTaxRange.Product_Images
      || currentTaxRange.Product_Images.length === 0
      || !currentTaxRange.Product_Color_Name
    ) {
      const validationErrors = {};
      if (!currentTaxRange.Cost_Price) {
        validationErrors.Cost_Price = "Cost Price is required";
      }
      if (currentTaxRange.Cost_Price < 0) {
        validationErrors.Cost_Price = "Cost Price must be positive";
      }
      if (!currentTaxRange.Selling_Price) {
        validationErrors.Selling_Price = "Selling Price is required";
      }
      if (currentTaxRange.Selling_Price < 0) {
        validationErrors.Selling_Price = "Selling Price must be positive";
      }
      // if (currentTaxRange && !currentTaxRange.Platform_Fee) {
      if (currentTaxRange.Platform_Fee === "" || currentTaxRange.Platform_Fee === null || currentTaxRange.Platform_Fee === undefined) {
        validationErrors.Platform_Fee = "Platform Fee is required";
      }
      if (currentTaxRange.Platform_Fee < 0) {
        validationErrors.Platform_Fee = "Platform Fee must be positive";
      }
      // if (!currentTaxRange.Product_Images.length > 0) {
      //   validationErrors.Product_Images = "Product Images is required";
      // }
      if (currentTaxRange.Product_Images.length === 0) {
        validationErrors.Product_Images = "Product Images are required";
      }
      if (!currentTaxRange.Product_Color_Name) {
        validationErrors.Product_Color_Name = "Product Color Name is required";
      }
      if (!currentTaxRange.Size.length > 0) {
        validationErrors.Size = "Product Size is required";
      }
      if (!brandNameCheck) {
        validationErrors.Brand_Name = "Brand Name is required";
      }
      if (!productNameCheck) {
        validationErrors.Product_Name = "Product Name is required";
      }
      if (Object.keys(validationErrors).length > 0) {
        setValidation({ ...validation, ...validationErrors });
      }
      return;
    }

    const isDuplicate = sizearray.find(item =>
      item.Size === newPriceDetails.Size &&
        item.Cost_Price === newPriceDetails.Cost_Price &&
        item.Selling_Price === newPriceDetails.Selling_Price &&
        item.Platform_Fee === newPriceDetails.Platform_Fee &&
        item.Discount_Value === newPriceDetails.Discount_Value &&
        item.Is_Thumbnail === newPriceDetails.Is_Thumbnail &&
        JSON.stringify(item.Product_Images) === JSON.stringify(newPriceDetails.Product_Images) &&
        item.Product_Color_Name === newPriceDetails.Product_Color_Name &&
        item.Product_Color_Id === newPriceDetails.Product_Color_Id ? newPriceDetails.Product_Color_Id : null &&
          item.Product_Price_On_Color_Size_Id === newPriceDetails.Product_Price_On_Color_Size_Id ? newPriceDetails.Product_Price_On_Color_Size_Id : null
    );
    if (!isDuplicate) {
      if (editIndex !== null && editIndex >= 0) {
        setArray(prevArray => {
          const updatedArray = [...prevArray];
          updatedArray[editIndex] = newPriceDetails;
          return updatedArray;
        });
        setEditIndex(null);
      } else {
        setArray(prevArray => [...prevArray, newPriceDetails]);
      }
    } else {
      console.log('Duplicate entry detected');
    }
    // setMediaImages({
    //   1: null,
    //   2: null,
    //   3: null,
    //   4: null,
    //   5: null,
    //   6: null,
    // })
    setMediaImages({
      1: { Product_Image_Id: null, Image_Path: null },
      2: { Product_Image_Id: null, Image_Path: null },
      3: { Product_Image_Id: null, Image_Path: null },
      4: { Product_Image_Id: null, Image_Path: null },
      5: { Product_Image_Id: null, Image_Path: null },
      6: { Product_Image_Id: null, Image_Path: null },
    })
    setFilterMRP(clothingopen ? filterMRP : 0);
    setDiscountRate(clothingopen ? discountRate : 0);
    setSelectedUnits([])
    setDiscountOpen(false)
    setCurrentTaxRange(prevState => ({
      ...prevState,
      Size: [],
      Product_Color_Name: '',
      Product_Color_Id: '',
      Product_Price_On_Color_Size_Id: '',
      Is_Thumbnail: false,
      Product_Images: [],
      Discount_Value: clothingopen ? prevState.Discount_Value : '',
      Cost_Price: clothingopen ? prevState.Cost_Price : '',
      Selling_Price: clothingopen ? prevState.Selling_Price : '',
      Platform_Fee: clothingopen ? prevState.Platform_Fee : '',
    }));
    setEditIndex(null);
  };

  const handleChangeBrandName = (value) => {
    setBrandName(value)
  };
  const handleChangeProductName = (value) => {
    setProductName(value)
  };
  const handleChangeAvailableUnits = (value) => {
  };
  const handleChangeDiscount = (value) => {
    setDiscountOpen(value)
    const calculatedDiscountRate = filterMRP > 0 ? Math.round(filterMRP - (currentTaxRange.Discount_Value / 100) * filterMRP) : 0;
    setDiscountRate(calculatedDiscountRate);
  };
  const handleChangeIsClothing = (value) => {
    setClothingOpen(value)
  };
  const handleChangeIsUserCustomizable = (value) => {
    setIsUserCustomizable(value)
  };

  const handleChangeAvailableUnit = (event, newValue) => {
    if (newValue.length >= 1) {
      setSelectedUnits([newValue[newValue?.length - 1]]);
      setCurrentTaxRange({ ...currentTaxRange, Size: newValue[newValue?.length - 1] })
    } else {
      setSelectedUnits(newValue);
      setCurrentTaxRange({ ...currentTaxRange, Size: newValue[0] })
    }
    setValidation()
  };

  const [filtercategories, setFiltercategories] = useState([]);
  const callBackCategoriesdata = async (status, data,) => {
    if (status) {
      setFiltercategories(data);
    }
  }

  useEffect(() => {
    if (productData && filtercategories.length > 0) {
      const selectedCategory = filtercategories.find(category => category._id === productData.Product_Category_Id);
      setUnitType(selectedCategory?.Measurement_Units || "");

    }
  }, [productData, filtercategories]);

  const mapImages = (productImages) => {
    const newImages = productImages.reduce((acc, img) => {
      if (img.Is_Front) acc.front = img.Image_Path;
      if (img.Is_Back) acc.back = img.Image_Path;
      if (img.Is_Left) acc.left = img.Image_Path;
      if (img.Is_Right) acc.right = img.Image_Path;
      if (img.Is_Top) acc.top = img.Image_Path;
      if (img.Is_Bottom) acc.bottom = img.Image_Path;
      return acc;
    }, { front: null, back: null, left: null, right: null, top: null, bottom: null });
    setImages(newImages);
  };

  const callBackSingleProductDetails = async (status, data) => {
    if (status) {
      setProductDatils(data);
      setUnits(data?.Product_Available_Sizes ? data.Product_Available_Sizes : []);
      mapImages(data.Product_Customizable_Images);
      mapSpecifications(data.Product_Specifications)
      setArray(transformProductData(data))
      setProductName(data.Product_Name)
      setBrandName(data.Brand_Name)
    }
  }

  const [productlabelOption, setProductlabelOption] = useState([]);

  const callBackProductlabelOption = async (status, data) => {
    if (status) {
      setProductlabelOption(data);
    }
  }
  const handleDeleteUnit = (unitToDelete) => {
    setUnits(units.filter(unit => unit?.Size_Type !== unitToDelete));
  };
  // Richtext editor
  const contentState = ContentState.createFromBlockArray(htmlToDraft(removePTags(state?.Product_Description) || "").contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [rhDiscription, setRhDiscription] = useState(removePTags(state?.Product_Description));
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setRhDiscription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // Richtext editor (ProductShortDescription)
  const contentStateProductShortDescription = ContentState.createFromBlockArray(htmlToDraft(removePTags(state?.Product_Short_Description) || "").contentBlocks);
  const [editorStateProductShortDescription, setEditorStateProductShortDescription] = useState(EditorState.createWithContent(contentStateProductShortDescription));
  const [rhDiscriptionProductShortDescriptionMin, setRhDiscriptionProductShortDescriptionMin] = useState(20);
  const [rhDiscriptionProductShortDescription, setRhDiscriptionProductShortDescription] = useState(removePTags(state?.Product_Short_Description));
  const onEditorStateChangeProductShortDescription = (editorStateProductShortDescription) => {
    setEditorStateProductShortDescription(editorStateProductShortDescription);
    setRhDiscriptionProductShortDescription(draftToHtml(convertToRaw(editorStateProductShortDescription.getCurrentContent())));
  };

  // file Upload code
  const positionRef = useRef('');
  const [images, setImages] = useState({
    front: null,
    back: null,
    left: null,
    right: null,
    top: null,
    bottom: null,
  });

  const handleChangeThumbnail = (value) => {
    setCurrentTaxRange({ ...currentTaxRange, Is_Thumbnail: value, Product_Images: imageUrls });
  };

  const handleChangeCostPrice = (e) => {
    setCurrentTaxRange({ ...currentTaxRange, Cost_Price: e.target.value });
    setValidation({ ...validation, Cost_Price: !e.target.value ? 'Cost_Price is required' : null });
    setValidation({ ...validation, Cost_Price: e.target.value < 0 ? 'Cost Price must be positive' : null });
  };

  const handleChangeDiscountValue = (event) => {
    const value = event.target.value;
    const discountValue = value !== '' ? parseFloat(value) : '';
    setCurrentTaxRange({ ...currentTaxRange, Discount_Value: discountValue });
    setValidation({
      ...validation,
      Discount_Value: value === '' ? 'Discount_Value is required' : (discountValue < 0 ? 'Discount Value must be positive' : null)
    });
    if (discountValue !== '') {
      handleChangeDiscountPrice(discountValue, filterMRP);
    }
  };

  const handleChangeSellingPrice = (e) => {
    const sellingPrice = parseInt(e.target.value);
    let validationMessage = null;
    if (!sellingPrice) {
      validationMessage = 'Selling Price is required';
    } else if (sellingPrice < 0) {
      validationMessage = 'Selling Price must be positive';
    } else if (sellingPrice <= currentTaxRange.Cost_Price) {
      validationMessage = 'Selling Price must be greater than Cost Price';
    }
    setCurrentTaxRange({ ...currentTaxRange, Selling_Price: sellingPrice });
    setValidation({ ...validation, Selling_Price: validationMessage });
  };

  const handleChangePlatformFee = (e) => {
    setCurrentTaxRange({ ...currentTaxRange, Platform_Fee: e.target.value });
    setValidation({ ...validation, Platform_Fee: !e.target.value ? 'Platform_Fee is required' : null });
    setValidation({ ...validation, Platform_Fee: e.target.value < 0 ? 'Platform Fee must be positive' : null });
  };
  // const handleChangeImage = () => {
  //   setCurrentTaxRange({ ...currentTaxRange, Product_Images: imageUrls });

  // };
  const handleChangeImage = () => {
    setCurrentTaxRange({
      ...currentTaxRange,
      Product_Images: imageUrls.map(img => ({
        Product_Image_Id: img.Product_Image_Id || null,
        Image_Path: img.Image_Path
      })),
    });
  };

  const handleChangeColor = (e) => {
    setCurrentTaxRange({ ...currentTaxRange, Product_Color_Name: e.target.value, Product_Images: imageUrls });
    setValidation({ ...validation, Product_Color_Name: !e.target.value ? 'Product_Color_Name is required' : null });
  };
  const callBackUploadImage = (data) => {
    if (data) {
      setImages((prevImages) => {
        const updatedImages = {
          ...prevImages,
          [positionRef.current]: data.path,
        };
        setImagesForInitialValues(createPayload(updatedImages));
        return updatedImages;
      });
    }
  };
  const [filterMRP, setFilterMRP] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);

  const callBackMRPdata = async (status, data) => {
    if (status) {
      const mrp = data?.MRP || 0;
      setFilterMRP(mrp);
      handleChangeDiscountPrice(currentTaxRange.Discount_Value, mrp);
    }
  };

  const handleChangeDiscountPrice = (discountValue, mrp) => {
    const calculatedDiscountRate = mrp > 0 ? Math.round(mrp - (discountValue / 100) * mrp) : 0;
    setDiscountRate(calculatedDiscountRate);
  };

  // const callBackUploadMediaImage = (data) => {
  //   if (data) {
  //     setMediaImages((prevImages) => {
  //       const updatedImages = {
  //         ...prevImages,
  //         [positionRef.current]: data.path,
  //       };
  //       createMediaPayload(updatedImages);
  //       setValidation()
  //       return updatedImages;
  //     });
  //   }
  // };

  const callBackUploadMediaImage = (data) => {
    if (data) {
      setMediaImages((prevImages) => {
        const updatedImages = {
          ...prevImages,
          [positionRef.current]: {
            Product_Image_Id: data.Product_Image_Id || null, // Assuming data has Product_Image_Id
            Image_Path: data.Image_Path || data.path || null, // Assuming data has Image_Path or path
          },
        };
        createMediaPayload(updatedImages);
        setValidation();
        return updatedImages;
      });
    }
  };

  const handleFileChange = (e, position) => {
    positionRef.current = position;
    e.preventDefault();
    const file = e.target.files[0];
    if (file && file.type === 'image/png' || file.type === 'image/jpeg') {
      const data = new FormData();
      data.append('file', file);
      dispatch(uploadCategoryImage(data, callBackUploadImage));
    } else {
      toast('Image type should be png/jpeg', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
  };
  const handleMediaFileChange = (e, position) => {
    positionRef.current = position;
    e.preventDefault();
    const file = e.target.files[0];
    if (file && file.type === 'image/png' || file.type === 'image/jpeg') {
      const data = new FormData();
      data.append('file', file);
      dispatch(uploadCategoryImage(data, callBackUploadMediaImage));
    } else {
      toast('Image type should be png/jpeg', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
    }
  };
  const positions = [
    { key: 'front', label: 'Front' },
    { key: 'back', label: 'Back' },
    { key: 'left', label: 'Left' },
    { key: 'right', label: 'Right' },
    { key: 'top', label: 'Top' },
    { key: 'bottom', label: 'Bottom' },
  ];

  const mediapositions = [
    { key: '1' },
    { key: '2' },
    { key: '3' },
    { key: '4' },
    { key: '5' },
    { key: '6' },
  ];
  const createPayload = (imagesData) => {
    const payload = positions.map((pos) => ({
      Product_Image: imagesData[pos.key],
      Is_Front: pos.key === 'front',
      Is_Back: pos.key === 'back',
      Is_Left: pos.key === 'left',
      Is_Right: pos.key === 'right',
      Is_Top: pos.key === 'top',
      Is_Bottom: pos.key === 'bottom',
    }));
    return payload;
  };
  const createMediaPayload = (imagesData) => {
    const payload = mediapositions.map((pos) => ({
      Product_Image: imagesData[pos.key],
      Imagekey: pos.key
    }));
    return payload;
  };
  // const handleMediaDelete = (position) => {
  //   setMediaImages((prevImages) => ({
  //     ...prevImages,
  //     [position]: null,
  //   }));
  // };
  const handleMediaDelete = (position) => {
    setMediaImages((prevImages) => ({
      ...prevImages,
      [position]: { Product_Image_Id: null, Image_Path: null },
    }));
  };

  const handleDelete = (position) => {
    setImages((prevImages) => {
      const updatedImages = { ...prevImages, [position]: null };

      const filteredImages = imagesforinitialvalues
        .map((image) => {
          if (position === 'back' && image.Is_Back) {
            return { ...image, Product_Image: null, Is_Back: null };
          }
          if (position === 'bottom' && image.Is_Bottom) {
            return { ...image, Product_Image: null, Is_Bottom: null };
          }
          if (position === 'front' && image.Is_Front) {
            return { ...image, Product_Image: null, Is_Front: null };
          }
          if (position === 'left' && image.Is_Left) {
            return { ...image, Product_Image: null, Is_Left: null };
          }
          if (position === 'right' && image.Is_Right) {
            return { ...image, Product_Image: null, Is_Right: null };
          }
          if (position === 'top' && image.Is_Top) {
            return { ...image, Product_Image: null, Is_Top: null };
          }
          return image;
        })
        .filter((image) => image.Product_Image !== null);

      setImagesForInitialValues(filteredImages);
      return updatedImages;
    });
  };

  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showDeleteIconformedia, setShowDeleteIconforMedia] = useState(false);

  const handleMouseEnter = () => {
    setShowDeleteIcon(true);
  };

  const handleMouseLeave = () => {
    setShowDeleteIcon(false);
  };
  const handleMediaMouseEnter = () => {
    setShowDeleteIconforMedia(true);
  };

  const handleMediaMouseLeave = () => {
    setShowDeleteIconforMedia(false);
  };
  const [editIndex, setEditIndex] = useState(null);

  // const handleEditTaxRange = (index) => {
  //   const selectedTaxRange = sizearray[index];
  //   setCurrentTaxRange(selectedTaxRange);
  //   setSelectedUnits([selectedTaxRange.Size]);
  //   dispatch(getMRP(state.Product_Category_Id, Number(selectedTaxRange?.Selling_Price) + Number(selectedTaxRange.Platform_Fee), callBackMRPdata))
  //   handleChangeDiscountPrice(selectedTaxRange.Discount_Value, filterMRP)

  //   setMediaImages(
  //     selectedTaxRange.Product_Images.reduce((acc, img, idx) => {
  //       acc[mediapositions[idx].key] = img;
  //       return acc;
  //     }, {})
  //   );
  //   setEditIndex(index);
  // };
  const handleEditTaxRange = (index) => {
    const selectedTaxRange = sizearray[index];
    setCurrentTaxRange(selectedTaxRange);
    setSelectedUnits([selectedTaxRange.Size]);
    dispatch(getMRP(state.Product_Category_Id, Number(selectedTaxRange?.Selling_Price) + Number(selectedTaxRange.Platform_Fee), callBackMRPdata));
    handleChangeDiscountPrice(selectedTaxRange.Discount_Value, filterMRP);
    setMediaImages(
      selectedTaxRange.Product_Images.reduce((acc, img, idx) => {
        acc[mediapositions[idx].key] = {
          Product_Image_Id: img.Product_Image_Id,
          Image_Path: img.Image_Path
        };
        return acc;
      }, {})
    );
    setEditIndex(index);
  };

  const handledeleteTaxRange = (index) => {
    const updatedTaxRanges = sizearray.filter((_, i) => i !== index);
    setArray(updatedTaxRanges);
  };

  //Specifications
  const [specifications, setSpecifications] = useState([
    { title: '', detail: '' },
    { title: '', detail: '' },
    { title: '', detail: '' },
    { title: '', detail: '' },
    { title: '', detail: '' },
  ]);

  const [payload, setPayload] = useState([]);
  const [specErrors, setSpecErrors] = useState([]);

  const mapSpecifications = (productSpecifications) => {
    const newSpecifications = productSpecifications.map((spec) => ({
      title: spec.Specification_Title || '',
      detail: spec.Specification_Detail || '',
    }));

    setSpecifications(newSpecifications);
  };
  useEffect(() => {
    // Update payload whenever specifications change
    const updatedPayload = specifications.map((spec) => ({
      Specification_Title: spec.title,
      Specification_Detail: spec.detail,
    }));
    setPayload(updatedPayload);
  }, [specifications]);

  useEffect(() => {
    if (productData?.Product_Customizable_Images?.length > 0) {
      const filteredImages = productData.Product_Customizable_Images.map((image) => ({
        Product_Image: image.Image_Path,
        Is_Back: image.Is_Back,
        Is_Bottom: image.Is_Bottom,
        Is_Front: image.Is_Front,
        Is_Left: image.Is_Left,
        Is_Right: image.Is_Right,
        Is_Top: image.Is_Top,
      }));
      setImagesForInitialValues(filteredImages);
    }
  }, [productData]);

  const handleChangeDetails = (index, field, value) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index][field] = value;
    // Validate the updated value
    const updatedErrors = [...specErrors];
    if (value && value.replace(/\s+/g, '').length === 0) {
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: `${field === 'title' ? 'Specification title' : 'Specification detail'} cannot be only spaces.`,
      };
    } else {
      updatedErrors[index] = {
        ...updatedErrors[index],
        [field]: '',
      };
    }
    setSpecifications(updatedSpecifications);
    setSpecErrors(updatedErrors);
  };

  return (
    <>
      {productData &&
        <Formik
          enableReinitialize={true}
          initialValues={{
            Product_Id: productData._id,
            Brand_Name: productData.Brand_Name ? productData.Brand_Name : "",
            Product_Name: productData.Product_Name,
            Product_Label_Id: productData.Product_Label_Id,
            Product_Category_Id: productData.Product_Category_Id,
            Status: productData.Status,
            Product_Description: removePTags(productData?.Product_Description) ? removePTags(productData?.Product_Description) : "",
            Product_Short_Description: productData?.Product_Short_Description,
            Product_Available_Sizes: unitIds,
            Corp_Product_Colors: transformData(sizearray),
            Product_Customizable_Images: imagesforinitialvalues,
            Is_User_Customizable: false,
            Product_Specifications: payload,
            Is_Clothing: false,
            Modified_By: loginEmail,
          }}

          validationSchema={
            Yup.object().shape({
              Brand_Name: Yup.string()
                .matches(/^[^\s]+(\s[^\s]+)*$/, 'Brand Name cannot start or end with a space and can have only single spaces in between')
                .required('Brand Name is required.'),
              Product_Name: Yup.string().min(3)
                .matches(/^[^\s]+(\s[^\s]+)*$/, 'Product Name cannot start or end with a space and can have only single spaces in between')
                .required('Product Name is required.'),
              Status: Yup.string().min(3)
                .required('Status is required.'),
            })
          }

          onSubmit={(values, { setSubmitting, setErrors }) => {
            let submitTest = true;
            setSubmitting(false);
            const errors = {};
            values.Product_Description = rhDiscription;
            values.Product_Short_Description = rhDiscriptionProductShortDescription;
            const plainTextDescription = rhDiscription.replace(/(<([^>]+)>)/ig, '').trim();
            const plainTextShortDescription = rhDiscriptionProductShortDescription.replace(/(<([^>]+)>)/ig, '').trim();
            const onlySpacesRegex = /^(|\s|&nbsp;)*$/;

            if (!plainTextDescription) {
              setDescError(!plainTextDescription)
              submitTest = false;
              errors.Product_Description = "Description is required.";
            }
            else if (plainTextDescription.length < rhDiscriptionMin) {
              setDescError(plainTextDescription.length < rhDiscriptionMin)
              submitTest = false;
              errors.Product_Description = `Please provide at least ${rhDiscriptionMin} characters.`;
            }
            else if (onlySpacesRegex.test(plainTextDescription)) {
              setDescError(onlySpacesRegex.test(plainTextDescription))
              submitTest = false;
              errors.Product_Description = "Description cannot consist of only spaces";
            }

            if (!plainTextShortDescription) {
              setShortDescError(!plainTextShortDescription)
              submitTest = false;
              errors.Product_Short_Description = "Product Short Description is required.";
            }
            else if (plainTextShortDescription.length < rhDiscriptionProductShortDescriptionMin) {
              setShortDescError(plainTextShortDescription.length < rhDiscriptionProductShortDescriptionMin)
              submitTest = false;
              errors.Product_Short_Description = `Please provide at least ${rhDiscriptionProductShortDescriptionMin} characters.`;
            }
            else if (onlySpacesRegex.test(plainTextShortDescription)) {
              setShortDescError(onlySpacesRegex.test(plainTextShortDescription))
              submitTest = false;
              errors.Product_Short_Description = "Product Short Description cannot consist of only spaces";
            }

            // Specifications validation
            specifications.forEach((spec, index) => {
              if (spec.title.trim() === ' ') {
                submitTest = false;
                errors[`specification_title_${index}`] = "Specification title cannot be only spaces.";
              }
              if (spec.detail.trim() === ' ') {
                submitTest = false;
                errors[`specification_detail_${index}`] = "Specification detail cannot be only spaces.";
              }
            });

            const validationErrors = {};
            if (Object.keys(validationErrors).length > 0) {
              setValidation({ ...validation, ...validationErrors });
            }
            setErrors(errors);
            if (submitTest) {
              let formValues = JSON.parse(JSON.stringify(values));
              formValues.Product_Description = plainTextDescription;
              formValues.Product_Short_Description = plainTextShortDescription;
              formValues.Product_Available_Sizes = unitIds
              formValues.Corp_Product_Colors = transformData(sizearray).map(({ BrandName, ProductName, ...rest }) => rest)
              formValues.Product_Customizable_Images = imagesforinitialvalues
              formValues.Product_Specifications = payload
              formValues.Is_Clothing = clothingopen
              formValues.Is_User_Customizable = isUserCustomizable
              dispatch(updateProductAction(formValues, navigate, setErrorMessage, setErrors));
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <ConnectedFocusError />
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12} style={{ marginBottom: '16px' }}>
                      <Typography style={{
                        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        marginBlock: '24px',
                        lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                        letterSpacing: '0.15px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>Product details</Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.Brand_Name && errors.Brand_Name || validation?.Brand_Name)}
                            fullWidth
                            helperText={touched.Brand_Name && errors.Brand_Name || validation?.Brand_Name}
                            onBlur={handleBlur}
                            label="Brand Name *"
                            name="Brand_Name"
                            onChange={(e) => { handleChange(e); handleChangeBrandName(e.target.value); setValidation() }}
                            value={values.Brand_Name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.Product_Name && errors.Product_Name || validation?.Product_Name)}
                            fullWidth
                            helperText={touched.Product_Name && errors.Product_Name || validation?.Product_Name}
                            onBlur={handleBlur}
                            label="Product Name *"
                            name="Product_Name"
                            onChange={(e) => { handleChange(e); handleChangeProductName(e.target.value); setValidation() }}
                            value={values.Product_Name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Product Label"
                            name="Product_Label_Id"
                            select
                            SelectProps={{ native: true }}
                            value={values.Product_Label_Id}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          >
                            <option key="" value="">--Please Select--</option>
                            {productlabelOption.map(option => (
                              <option key={option.id} value={option.id}>
                                {option.Lable_Name}
                              </option>
                            ))}
                          </TextField>

                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginBottom: '16px' }}>
                      <Typography style={{
                        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                        fontFamily: 'Roboto',
                        marginBlock: '24px',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                        letterSpacing: '0.15px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>Product attributes</Typography>
                      <Grid container spacing={3}>
                        {/* Product_Category_Id */}
                        <Grid item xs={12}>
                          <Autocomplete
                            freeSolo
                            options={filtercategories}
                            groupBy={(option) => option.firstLetter}
                            id="combo-box-demo"
                            value={filtercategories.find(option => option._id === values.Product_Category_Id) || null}
                            getOptionLabel={(option) => option ? option.Category_Name || option._id : ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // error={Boolean(touched.Product_Category_Id && errors.Product_Category_Id)}
                                // helperText={touched.Product_Category_Id && errors.Product_Category_Id}
                                onBlur={handleBlur}
                                label="Product Category"
                                name="Product_Category_Id"
                                variant="outlined"
                                onChange={(e) => {
                                  dispatch(getAllFilterCategories(e.target.value, callBackCategoriesdata));
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  autoComplete: 'off', // disable autocomplete and autofill
                                  startAdornment: (
                                    <React.Fragment>
                                      <SearchIcon color="inherit" size={20} />
                                      {params.InputProps.startAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                            onChange={(e, value) => {
                              setUnits(value?.Available_Capacity ? value.Available_Capacity : []);
                              setUnitType(value?.Measurement_Units ? value.Measurement_Units : "");
                              setValidation();
                              setFieldValue("Product_Category_Id", value?._id || "");
                            }}
                            disabled
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            disabled
                            label="Measurement unit *"
                            value={unitType}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center">
                            <Autocomplete
                              disabled
                              multiple
                              options={[]}
                              freeSolo
                              fullWidth
                              // error={Boolean(validation?.AVAILABLEUNITS)}
                              label="Available Units"
                              value={units.map(item => item.Size_Type)}
                              onChange={(event, newValue) => {
                                setUnits(newValue);
                                handleChangeAvailableUnits(newValue)
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option}
                                    deleteIcon={<CloseIcon style={{ color: '#2196F3', background: "#E6E6E6", fontSize: '16px', borderRadius: '50px' }} />}
                                    {...getTagProps({ index })}
                                    onDelete={() => handleDeleteUnit(option)}
                                    style={{ margin: 2, color: 'white', background: "#2196F3" }}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  // error={Boolean(validation?.AVAILABLEUNITS)}
                                  // helperText={validation?.AVAILABLEUNITS}
                                  variant="outlined"
                                  label="Available Units"
                                  style={{ flexGrow: 1 }}
                                />
                              )}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography style={{
                        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        marginBottom: '24px',
                        lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                        letterSpacing: '0.15px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>Customizable sides</Typography>
                      <Grid item style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }} xs={12}>
                        <Typography variant='body2' style={{
                          color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          marginBottom: '24px',
                          lineHeight: '143%', // or '21.98px' if you prefer the pixel value
                          letterSpacing: '0.17px',
                          display: 'flex',
                          alignItems: 'center'
                        }}>Variant 1
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={isUserCustomizable}
                              onChange={(e) => handleChangeIsUserCustomizable(!isUserCustomizable)}
                              color="primary"
                            />
                          }
                          label="Is user customizable"
                        />
                      </Grid>
                      <Grid container style={{ flexDirection: 'row', gap: '24px var(--3, 24px)', margin: '24px' }}>
                        {positions.map((pos) => (
                          <Grid key={pos.key} style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle1">{pos.label}</Typography>
                            <Box
                              sx={{
                                border: '2px dashed #2196F3',
                                borderRadius: '8px',
                                width: '150px',
                                height: '150px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                overflow: 'hidden',
                                position: 'relative',
                              }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              {images[pos.key] ? (
                                <>
                                  <img
                                    src={images[pos.key]}
                                    alt={pos.label}
                                    style={{
                                      width: '100%', height: '100%', objectFit: 'cover',
                                      '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                      },
                                    }}
                                  />
                                  {showDeleteIcon && (
                                    <IconButton
                                      onClick={() => handleDelete(pos.key)}
                                      style={{
                                        position: 'absolute', // Absolute position to be on top of the image
                                        top: '70px', // Position it as needed
                                        right: '30px', // Position it as needed
                                        display: 'flex',
                                        alignContent: 'center',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </>
                              ) : (
                                <>
                                  <UploadFileIcon sx={{ fontSize: 40, color: '#2196F3' }} />
                                  <Button component="label" variant="text" style={{ color: '#2196F3', marginTop: 1 }}>
                                    Upload
                                    <input type="file" hidden onChange={(e) => handleFileChange(e, pos.key)} />
                                  </Button>
                                </>
                              )}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Typography style={{
                        color: 'red',
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                        letterSpacing: '0.15px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>{validation?.Customizable_sides}</Typography>
                      <Typography style={{
                        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        marginBottom: '24px',
                        lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                        letterSpacing: '0.15px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>Product specification</Typography>
                      <Grid container style={{ flexDirection: 'row', gap: '16px var(--3, 16px)', marginBottom: '24px' }}>
                        {specifications.map((spec, index) => (
                          <Box
                            key={index}
                            sx={{
                              border: '1px solid #e0e0e0',
                              borderRadius: '8px',
                              padding: '16px',
                              width: '190px',
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              Specification {index + 1}
                            </Typography>
                            <TextField
                              label="Specification title"
                              value={spec.title}
                              onChange={(e) => handleChangeDetails(index, 'title', e.target.value)}
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              error={!!specErrors[index]?.title}
                              helperText={specErrors[index]?.title}
                            />
                            <TextField
                              label="Specification detail"
                              value={spec.detail}
                              onChange={(e) => handleChangeDetails(index, 'detail', e.target.value)}
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              error={!!specErrors[index]?.detail}
                              helperText={specErrors[index]?.detail}
                            />
                          </Box>
                        ))}
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Box className={classes.description}>
                            Description
                            <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} />
                            {/* <EditorContainer editorState={editorState} onEditorStateChange={onEditorStateChange} /> */}
                          </Box>
                          <span style={{ color: "red" }}>
                            {descError && touched.Product_Description && errors.Product_Description}
                          </span>

                        </Grid>
                        {/* ProductShortDescription  */}
                        <Grid item md={6} xs={12}>
                          <Box className={classes.description}>
                            Product Short Description
                            <EditorContainer editorState={editorStateProductShortDescription} onEditorStateChange={onEditorStateChangeProductShortDescription} />
                          </Box>
                          <span style={{ color: "red" }}>
                            {shortdescError && touched.Product_Short_Description && errors.Product_Short_Description}
                          </span>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.Status && errors.Status)}
                            fullWidth
                            helperText={touched.Status && errors.Status}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Status"
                            name="Status"
                            select
                            SelectProps={{ native: true }}
                            value={values.Status}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          >
                            {statusOption.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={clothingopen}
                                onChange={(e) => handleChangeIsClothing(!clothingopen)}
                                color="primary"
                              />
                            }
                            label="Is Clothing"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {sizearray.length ? <Grid item md={12} xs={12} style={{ marginBottom: '16px' }}>
                      <Typography style={{
                        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        marginBlock: '24px',
                        lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                        letterSpacing: '0.15px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>Created variants {sizearray.length} </Typography>
                      {sizearray.length > 0 ? <Grid item xs={12} style={{ gap: 'var(--1,16px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {sizearray.map((item, index) => (
                          <Grid key={index} style={{ width: '156px', height: '290px', gap: 'var(--1,8px' }}>
                            <Grid key={index} style={{ width: '156px', height: '172px' }}>
                              <img
                                // src={item.Product_Images[0]}
                                src={item.Product_Images.length > 0 ? item.Product_Images[0].Image_Path : 'default-image-url'}
                                alt={'product Image'}
                                style={{
                                  width: '100%', height: '100%', objectFit: 'cover',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                  },
                                }}
                              />
                            </Grid>
                            <Typography style={{
                              color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                              fontFamily: 'Roboto',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '166%', // or '21.98px' if you prefer the pixel value
                              letterSpacing: '0.4px',
                              display: 'flex',
                              alignItems: 'center',
                              alignSelf: 'stretch'
                            }}>{item?.BrandName}
                            </Typography>
                            <Typography style={{
                              color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                              fontFamily: 'Roboto',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '166%', // or '21.98px' if you prefer the pixel value
                              letterSpacing: '0.4px',
                              display: 'flex',
                              alignItems: 'center',
                              alignSelf: 'stretch'
                            }}>{item?.ProductName} ({item?.Size}) </Typography>
                            <Typography style={{
                              color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                              fontFamily: 'Roboto',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '166%', // or '21.98px' if you prefer the pixel value
                              letterSpacing: '0.4px',
                              display: 'flex',
                              alignItems: 'center',
                              alignSelf: 'stretch'
                            }}>Price : {item?.Selling_Price} </Typography>
                            <Box style={{ flexDirection: 'row', display: 'flex', margin: '5px' }}>
                              <Button variant="contained" color="secondary" size="small"
                                onClick={() => handleEditTaxRange(index)}>
                                Edit
                              </Button>
                              <Button variant="contained" size="small"
                                onClick={() => handledeleteTaxRange(index)}
                                style={{ marginLeft: '5px', display: 'flex', alignItems: 'flex-end', color: '#D32F2F' }}>
                                Remove
                              </Button>
                            </Box>

                          </Grid>
                        ))}
                      </Grid>
                        : null}
                    </Grid> : null}
                    <Grid item md={12} xs={12}>
                      <Grid item md={12} xs={12}>
                        <Typography style={{
                          color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                          fontFamily: 'Roboto',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          marginBottom: '24px',
                          lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                          letterSpacing: '0.15px',
                          display: 'flex',
                          alignItems: 'center'
                        }}>Media</Typography>

                        <Grid item style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }} xs={12}>
                          <Typography variant='body2' style={{
                            color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            marginBottom: '24px',
                            lineHeight: '143%', // or '21.98px' if you prefer the pixel value
                            letterSpacing: '0.17px',
                            display: 'flex',
                            alignItems: 'center'
                          }}>Variant 1
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onBlur={handleBlur}
                                checked={currentTaxRange.Is_Thumbnail}
                                onChange={(e) => handleChangeThumbnail(!currentTaxRange.Is_Thumbnail)}
                                name="Is_Thumbnail"
                                color="primary"
                              />
                            }
                            label="Set as thumbnail"
                          />
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              error={Boolean(validation?.Product_Color_Name)}
                              helperText={validation?.Product_Color_Name}
                              onBlur={handleBlur}
                              label="Color"
                              name="Product_Color_Name"
                              onChange={(e) => handleChangeColor(e)}
                              value={currentTaxRange.Product_Color_Name}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              multiple
                              options={units.map(item => item.Size_Type)}
                              freeSolo={false}
                              name="Size"
                              error={Boolean(validation?.Size)}
                              value={selectedUnits}
                              onChange={(event, newValue) => handleChangeAvailableUnit(event, newValue)}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option}
                                    deleteIcon={<CloseIcon style={{ color: '#2196F3', background: "#E6E6E6", fontSize: '16px', borderRadius: '50px' }} />}
                                    {...getTagProps({ index })}
                                    style={{ margin: 2, color: 'white', background: "#2196F3" }}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="Size"
                                  error={Boolean(validation?.Size)}
                                  helperText={validation?.Size}
                                  variant="outlined"
                                  label="Available sizes"
                                  style={{ flexGrow: 1 }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid container style={{ flexDirection: 'row', gap: '24px var(--3, 24px)', margin: '24px' }}>
                          {mediapositions.map((pos) => (
                            <Box
                              key={pos.key}
                              sx={{
                                border: '2px dashed #2196F3',
                                borderRadius: '8px',
                                width: '150px',
                                height: '150px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                overflow: 'hidden',
                                position: 'relative',
                              }}
                              onMouseEnter={handleMediaMouseEnter}
                              onMouseLeave={handleMediaMouseLeave}
                            >
                              {/* {mediaimages[pos.key] ? ( */}
                              {mediaimages[pos.key]?.Image_Path ? (
                                <>
                                  <img
                                    // src={mediaimages[pos.key]}
                                    // alt={pos.key}
                                    src={mediaimages[pos.key]?.Image_Path}
                                    alt={`media-${pos.key}`}
                                    style={{
                                      width: '100%', height: '100%', objectFit: 'cover',
                                      '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                      },
                                    }}
                                  />
                                  {showDeleteIconformedia && (
                                    <IconButton
                                      onClick={() => handleMediaDelete(pos.key)}
                                      style={{
                                        position: 'absolute', // Absolute position to be on top of the image
                                        top: '70px', // Position it as needed
                                        right: '30px', // Position it as needed
                                        display: 'flex',
                                        alignContent: 'center',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </>
                              ) : (
                                <>
                                  <UploadFileIcon sx={{ fontSize: 40, color: '#2196F3' }} />
                                  <Button component="label" variant="text" style={{ color: '#2196F3', marginTop: 1 }}>
                                    Upload
                                    <input type="file" hidden onChange={(e) => handleMediaFileChange(e, pos.key)} />
                                  </Button>
                                </>
                              )}
                            </Box>
                          ))}
                        </Grid>
                        <Typography style={{
                          color: 'red',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                          letterSpacing: '0.15px',
                          display: 'flex',
                          alignItems: 'center'
                        }}>{validation?.Product_Images}</Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Typography style={{
                          color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                          fontFamily: 'Roboto',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          marginBottom: '24px',
                          lineHeight: '160%', // or '21.98px' if you prefer the pixel value
                          letterSpacing: '0.15px',
                          display: 'flex',
                          alignItems: 'center'
                        }}>Pricing</Typography>
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              error={Boolean(validation?.Cost_Price)}
                              helperText={validation?.Cost_Price}
                              onBlur={handleBlur}
                              label="Cost price *"
                              name="Cost_Price"
                              type="number"
                              onChange={(e) => handleChangeCostPrice(e)}
                              value={currentTaxRange.Cost_Price}
                              variant="outlined"
                              disabled={clothingopen && sizearray?.length > 0}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Box display="flex" alignItems="center">
                              <TextField
                                fullWidth
                                error={Boolean(validation?.Selling_Price)}
                                helperText={validation?.Selling_Price}
                                onBlur={handleBlur}
                                label="Selling price *"
                                name="Selling_Price"
                                type="number"
                                onChange={(e) => {
                                  handleChangeSellingPrice(e);
                                  dispatch(getMRP(values.Product_Category_Id, Number(e.target.value) + Number(currentTaxRange?.Platform_Fee), callBackMRPdata))
                                }}
                                value={currentTaxRange.Selling_Price}
                                variant="outlined"
                                disabled={clothingopen && sizearray?.length > 0}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              error={Boolean(validation?.Platform_Fee)}
                              helperText={validation?.Platform_Fee}
                              onBlur={handleBlur}
                              label="Platform Fee *"
                              name="Platform_Fee"
                              type="number"
                              onChange={(e) => {
                                handleChangePlatformFee(e);
                                dispatch(getMRP(values.Product_Category_Id, Number(currentTaxRange?.Selling_Price) + Number(e.target.value), callBackMRPdata))
                              }}
                              value={currentTaxRange.Platform_Fee}
                              variant="outlined"
                              disabled={clothingopen && sizearray?.length > 0}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="MRP"
                              disabled
                              type="number"
                              name="MRP"
                              value={Number(filterMRP)}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onBlur={handleBlur}
                              checked={discountopen}
                              onChange={(e) => handleChangeDiscount(!discountopen)}
                              color="primary"
                            />
                          }
                          label="Add discount"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        {discountopen && <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              error={Boolean(validation?.Discount_Value)}
                              helperText={validation?.Discount_Value}
                              onBlur={handleBlur}
                              label="Discount Value *"
                              name="Discount_Value"
                              type="number"
                              onChange={(e) => { handleChangeDiscountValue(e) }}
                              value={currentTaxRange.Discount_Value}
                              variant="outlined"
                              disabled={clothingopen && sizearray?.length > 0}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="Discounted Price *"
                              name="Discounted_Price"
                              type="number"
                              value={discountRate}
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box display="flex" pl={2} >
                    <span style={{ color: "red" }}>{errorMessage?.global?.message}</span>
                  </Box>
                  {validation && validation?.Cost_Price != null && validation?.Platform_Fee != null && validation?.Selling_Price != null ? <Grid style={{ color: 'red' }}>
                    Please fill All the Details
                  </Grid> : null}
                </CardContent>
                {editIndex !== null ?
                  <Box display="flex" p={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" onClick={(e) => handleClickForAddMore()} style={{ color: '#27B6CC', borderColor: '#27B6CC' }}
                      endIcon={<FileUploadIcon style={{ color: '#27B6CC' }} />}>
                      Update variant {editIndex + 1}
                    </Button>
                    {isSubmited ?
                      <CircularProgress />
                      :
                      <Button
                        disabled={isSubmited || sizearray.length <= 0}
                        type="submit"
                        style={{ backgroundColor: '#27B6CC' }}
                        variant="contained"
                      >
                        {/* Save details */}
                        <span style={{ color: 'white' }}>Save details</span>
                      </Button>
                    }
                  </Box> :
                  <Box display="flex" p={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" onClick={(e) => handleClickForCopy()} style={{ color: '#27B6CC', borderColor: '#27B6CC' }} endIcon={<ContentCopyIcon style={{ color: '#27B6CC' }} />}>
                      Duplicate  Properties
                    </Button>
                    <Button variant="outlined" onClick={(e) => handleClickForAddMore()} style={{ color: '#27B6CC', borderColor: '#27B6CC' }}
                      endIcon={<AddIcon style={{ color: '#27B6CC' }} />}>
                      Add more  variant
                    </Button>
                    {isSubmited ?
                      <CircularProgress />
                      :
                      <Button
                        disabled={isSubmited || sizearray.length <= 0}
                        type="submit"
                        style={{ backgroundColor: '#27B6CC' }}
                        variant="contained"
                      >
                        {/* Save details */}
                        <span style={{ color: 'white' }}>Save details</span>
                      </Button>
                    }
                  </Box>}
              </Card>
            </form>
          )}
        </Formik>
      }
    </>
  );
};

EditProduct.propTypes = {
  className: PropTypes.string
};

export default EditProduct;