import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const QuizForm = () => {
  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          label="Number of Questions"
          name="QuizQuestions"
          type="number"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default QuizForm;
