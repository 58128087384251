import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    hide: 0,
    link: '/app/pending-corporate-user-management',
    createdAt: '',
    description: 'All corporate approvals currently awaiting action. It includes the total number of pending approvals, submission dates, and the names or departments responsible for the next steps. Users can click on the card for detailed information and use filtering options to prioritize and manage pending approvals efficiently.',
    media: '/static/Corporate/PendingUsers.png',
    title: 'Pending Corporate Users',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/approved-corporate-user-management',
    createdAt: '',
    description:
      'All corporate approvals that have been granted, including the total number of approvals, approval dates, and names or departments of the approvers. Users can click on the card for detailed information and use filtering options to review and manage approved corporate actions efficiently.',
    media: '/static/Corporate/ApprovedUsers.png',
    title: 'Approved Corporate Users',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/rejected-corporate-user-management',
    createdAt: '',
    description: 'All declined corporate approvals, including the total number of rejections, reasons, dates, and names or departments of the rejectors. Users can click on the card for detailed information and use filtering options to manage and address rejections efficiently.',
    media: '/static/Corporate/RejectedUsers.png',
    title: 'Rejected Corporate Users',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-category-management',
    createdAt: '',
    description: 'An overview of how various product categories are organized and managed within the organization. It includes information on the total number of categories, key metrics for each category.',
    media: '/static/Corporate/Categories.png',
    title: 'Corporate Category Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-product-management',
    createdAt: '',
    description:
      'An overview of all products offered by the organization specifically tailored for corporate clients.',
    media: '/static/Corporate/Products.png',
    title: 'Corporate Product Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-coupon-management',
    createdAt: '',
    description: 'An overview of all available corporate coupons, including the total number of active coupons, expiration dates, and discount details.',
    media: '/static/Corporate/Coupons.png',
    title: 'Corporate Coupon Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-gifting-management',
    createdAt: '',
    description: 'All activities related to employee gifting and recognitions, including the distribution of gift cards and acknowledgment of achievements. It includes the total number of gifts and recognitions, dates, and the names of recipients.',
    media: '/static/Corporate/Gifts.png',
    title: 'Corporate Gifting Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporatefaq-management',
    createdAt: '',
    description: 'Serves as a centralized resource for frequently asked questions related to corporate policies, procedures, and information. It includes categorized questions and their respective answers, updated regularly to ensure accuracy and relevance.',
    media: '/static/Corporate/FAQ.png',
    title: 'Corporate FAQs',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-content-management',
    createdAt: '',
    description: 'All content-related activities within the organization. It includes the total number of content pieces, status updates (draft, reviewed, published), submission dates, and the names or departments responsible for the content.',
    media: '/static/Corporate/CMS.png',
    title: 'Corporate Content Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-ticket-management',
    createdAt: '',
    description: 'All active corporate tickets, including the total number of tickets, issue descriptions, submission dates, and the responsible departments or individuals.',
    media: '/static/Corporate/Tickets.png',
    title: 'Corporate Ticket Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-newsandevents-management',
    createdAt: '',
    description: 'Serves as a platform for disseminating important updates and news within the organization. It includes announcements related to new initiatives, policy changes, upcoming events, and achievements.',
    media: '/static/Corporate/Announcements.png',
    title: 'Corporate News and Events',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-order-management',
    createdAt: '',
    description: 'A comprehensive view of all corporate orders, from initiation to fulfillment. It includes details such as the total number of orders, order statuses, order dates,  Delivery type , company name etc.',
    media: '/static/Corporate/Order.png',
    title: 'Corporate Order Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-award-management',
    createdAt: '',
    description: 'A comprehensive view of award management, covering all processes from nomination to award distribution. It includes details such as total nominations, award categories, nomination statuses, award dates, distribution type, and recipient company names.',
    media: '/static/Corporate/Awards.png',
    title: 'Corporate Award Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corporate-popup-management',
    createdAt: '',
    description: 'Pop up management allows administrators to create, manage, and schedule popups to communicate important information effectively across the platform.This feature enables targeted messaging to users, ensuring they receive updates, announcements, or alerts at the right time.',
    media: '/static/Corporate/Popup.png',
    title: 'Corporate Popup Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corpemp-happiness-survey-management',
    createdAt: '',
    description: 'An overview of all employee surveys, including the total number of surveys available, their distribution status, and how they contribute to gathering employee feedback for organizational insights.',
    media: '/static/Corporate/Survey.png',
    title: 'Corporate Survey Management',
    total: ''
  },
  {
    id: uuid(),
    hide: 0,
    link: '/app/corpemp-activity-management',
    createdAt: '',
    description: 'An overview of all employee surveys, including the total number of surveys available, their distribution status, and how they contribute to gathering employee feedback for organizational insights.',
    media: '/static/Corporate/Survey.png',
    title: 'Corporate Activity Management',
    total: ''
  },
];