import { toast } from 'react-toastify';
import { paginationAllCorpEmpActivityGet } from './node-actions/nodepagination';
import { corpimageuploadApi, nodeCorpMongoApi } from './commonAxios';

export const CORPEMP_ACTIVITY_CREATE_REQUEST = "CORPEMP_ACTIVITY_CREATE_REQUEST";
export const CORPEMP_ACTIVITY_CREATE_SUCCESS = "CORPEMP_ACTIVITY_CREATE_SUCCESS";
export const CORPEMP_ACTIVITY_CREATE_FAIL = "CORPEMP_ACTIVITY_CREATE_FAIL";

export const CORPEMP_ACTIVITY_UPDATE_REQUEST = "CORPEMP_ACTIVITY_UPDATE_REQUEST";
export const CORPEMP_ACTIVITY_UPDATE_SUCCESS = "CORPEMP_ACTIVITY_UPDATE_SUCCESS";
export const CORPEMP_ACTIVITY_UPDATE_FAIL = "CORPEMP_ACTIVITY_UPDATE_FAIL";

export const CORPEMP_QUIZ_CREATE_REQUEST = "CORPEMP_QUIZ_CREATE_REQUEST";
export const CORPEMP_QUIZ_CREATE_SUCCESS = "CORPEMP_QUIZ_CREATE_SUCCESS";
export const CORPEMP_QUIZ_CREATE_FAIL = "CORPEMP_QUIZ_CREATE_FAIL";

export const CORPEMP_QUIZ_UPDATE_REQUEST = "CORPEMP_QUIZ_UPDATE_REQUEST";
export const CORPEMP_QUIZ_UPDATE_SUCCESS = "CORPEMP_QUIZ_UPDATE_SUCCESS";
export const CORPEMP_QUIZ_UPDATE_FAIL = "CORPEMP_QUIZ_UPDATE_FAIL";

export const CORP_JIGSAW_PUZZLE_CREATE_REQUEST = "CORP_JIGSAW_PUZZLE_CREATE_REQUEST";
export const CORP_JIGSAW_PUZZLE_CREATE_SUCCESS = "CORP_JIGSAW_PUZZLE_CREATE_SUCCESS";
export const CORP_JIGSAW_PUZZLE_CREATE_FAIL = "CORP_JIGSAW_PUZZLE_CREATE_FAIL";

export const CORP_JIGSAW_PUZZLE_UPDATE_REQUEST = "CORP_JIGSAW_PUZZLE_UPDATE_REQUEST";
export const CORP_JIGSAW_PUZZLE_UPDATE_SUCCESS = "CORP_JIGSAW_PUZZLE_UPDATE_SUCCESS";
export const CORP_JIGSAW_PUZZLE_UPDATE_FAIL = "CORP_JIGSAW_PUZZLE_UPDATE_FAIL";
//Create
export const addCorpEmpActivityAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPEMP_ACTIVITY_CREATE_REQUEST
    });
    nodeCorpMongoApi.post(`/corpOfflineActivity/create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 201) {
                dispatch({
                    type: CORPEMP_ACTIVITY_CREATE_SUCCESS
                });
                toast('Activity added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corpemp-activity-management');
            } else {
                dispatch({
                    type: CORPEMP_ACTIVITY_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPEMP_ACTIVITY_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//Update
export const updateCorpEmpActivityAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPEMP_ACTIVITY_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`/corpOfflineActivity/update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 200) {
                dispatch({
                    type: CORPEMP_ACTIVITY_UPDATE_SUCCESS
                });
                toast('Activity updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corpemp-activity-management');
            } else {
                dispatch({
                    type: CORPEMP_ACTIVITY_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPEMP_ACTIVITY_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Delete
export const deleteCorpEmpActivityAction = (formData, filters, pagination, callBackPagination) => async dispatch => {
    try {
        let { data } = await nodeCorpMongoApi.post(`/corpActivityManagement/delete`, formData);
        if (data) {
            toast('Activity deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            dispatch(paginationAllCorpEmpActivityGet(filters, pagination, callBackPagination));
        }
    } catch (err) {
    }
};

export const uploadActivityImage = (formData, callBackUploadImage) => async dispatch => {
    try {
        let { data } = await corpimageuploadApi.post(`/`, formData);
        if (data) {
            callBackUploadImage(data);
        }
    } catch (err) { }
};

//Quiz Create

export const addCorpEmpQuizAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPEMP_QUIZ_CREATE_REQUEST
    });
    nodeCorpMongoApi.post(`/corpHappinessIndexSurvey/create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 201) {
                dispatch({
                    type: CORPEMP_QUIZ_CREATE_SUCCESS
                });
                toast('Activity added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corpemp-activity-management');
            } else {
                dispatch({
                    type: CORPEMP_QUIZ_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPEMP_QUIZ_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

//Update
export const updateCorpEmpQuizAction = (formData, navigate) => async dispatch => {
    dispatch({
        type: CORPEMP_QUIZ_UPDATE_REQUEST
    });
    nodeCorpMongoApi.post(`/corpHappinessIndexSurvey/update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.code === 200) {
                dispatch({
                    type: CORPEMP_QUIZ_UPDATE_SUCCESS
                });
                toast('Activity updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                navigate('/app/corpemp-activity-management');
            } else {
                dispatch({
                    type: CORPEMP_QUIZ_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: CORPEMP_QUIZ_UPDATE_FAIL,
                payload: "Currently server is not working. Please try again later."
            });
        })
};

//Puzzle game Actions from here

export const uploadPuzzleGameImage = (
  formData,
  callBackUploadImgae,
) => async dispatch => {
  try {
    let { data } = await nodeCorpMongoApi.post(`/FileUpload/upload`, formData);
    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) { }
};


export const addCorpJigsawPuzzleAction = (formData, navigate) => async dispatch => {
  dispatch({
      type: CORP_JIGSAW_PUZZLE_CREATE_REQUEST
  });
  nodeCorpMongoApi.post(`/corpOfflineActivity/create`, formData)
      .then(response => {
          let successResponse = response.data;
          if (successResponse?.code === 201) {
              dispatch({
                  type: CORP_JIGSAW_PUZZLE_CREATE_SUCCESS
              });
              toast('Activity added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
              navigate('/app/corpemp-activity-management');
          } else {
              dispatch({
                  type: CORP_JIGSAW_PUZZLE_CREATE_FAIL,
                  payload: successResponse.UI_Display_Message
              });
          }
      })
      .catch(error => {
          dispatch({
              type: CORP_JIGSAW_PUZZLE_CREATE_FAIL,
              payload: "Please try sometime later.Currently server is not working."
          });
      })
};

//Update
export const updateCorpJigsawPuzzleAction = (formData, navigate) => async dispatch => {
  dispatch({
      type: CORP_JIGSAW_PUZZLE_UPDATE_REQUEST
  });
  nodeCorpMongoApi.post(`/corpOfflineActivity/update`, formData)
      .then(response => {
          let successResponse = response.data;
          if (successResponse?.code === 200) {
              dispatch({
                  type: CORP_JIGSAW_PUZZLE_UPDATE_SUCCESS
              });
              toast('Activity updated successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
              navigate('/app/corpemp-activity-management');
          } else {
              dispatch({
                  type: CORP_JIGSAW_PUZZLE_UPDATE_FAIL,
                  payload: successResponse.UI_Display_Message
              });
          }
      })
      .catch(error => {
          dispatch({
              type: CORP_JIGSAW_PUZZLE_UPDATE_FAIL,
              payload: "Currently server is not working. Please try again later."
          });
      })
};
