import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles,
    Dialog,
    DialogTitle,
    IconButton,
    Typography,
    Grid,
    Card,
    CardContent,
    DialogContent
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    formFilterControl: {
        margin: theme.spacing(1),
        minWidth: 200
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
}));

const activities = [
    {
        title: 'Offline activity',
        description: 'Design team-based or individual offline games with custom rules',
        image: '/static/Offline-activity.png',
        path: '/app/create-corpemp-offline-activity',
    },
    {
        title: 'Quiz',
        description: 'Create engaging quizzes to test knowledge and boost interaction',
        image: '/static/Quiz.png',
        // path: '/app/create-corpemp-quiz',
    },
    {
        title: 'Puzzle',
        description: 'Engage in fun puzzles to boost logic and problem-solving skills.',
        image: '/static/Puzzle.png',
        path: '/app/create-puzlle-actiity',
    },
    {
        title: 'Wellness activity',
        description: 'Set up wellness programs to encourage health and mindfulness',
        image: '/static/Wellness-activity.png',
        path: '/wellness-activity',
    },
];

const Toolbar = ({ className, filters, setfilters, RolePermissions, roleUserType, ...rest }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleChange = event => {
        setfilters({
            Search: filters?.Search,
            Search_By_Filter: event.target.value
        })
    };

    const handleNavigation = (path) => {
        setOpen(false);
        navigate(path);
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                alignItems="center"
                mb={2}
            >
                <Box display="flex" flexWrap="wrap" mb={2}>
                    <FormControl variant="outlined" className={classes.formFilterControl}>
                        <Select
                            value={filters.Search_By_Filter}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="id">Unique id</MenuItem>
                            <MenuItem value="Activity_Name">Activity name</MenuItem>
                            <MenuItem value="Activity_Type">Activity type</MenuItem>
                            <MenuItem value="Category">Category</MenuItem>
                            <MenuItem value="Created_On">Created on</MenuItem>
                            <MenuItem value="Status">Status</MenuItem>
                        </Select>
                    </FormControl>
                    <Box width={500}>
                        <TextField
                            onChange={(event) => {
                                setfilters({
                                    Search: event.target.value,
                                    Search_By_Filter: filters.Search_By_Filter
                                })
                            }}
                            className={classes.formControl}
                            value={filters?.Search}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search Activity"
                            variant="outlined"
                        />
                    </Box>
                </Box>
                {(roleUserType.User_Type === "ADMIN" || roleUserType.User_Type === "SUPER_ADMIN") ||
                    (RolePermissions?.CorpEmp_Activity_Management_Write?.Value === "CorpEmp_Activity_Management_Write" && RolePermissions?.CorpEmp_Activity_Management_Write?.Is_Write === 1) ?
                    <Button
                        onClick={() => setOpen(true)}
                        color="primary"
                        variant="contained"
                        style={{ marginTop: '-1.5%', backgroundColor: '#27B6CC' }}
                    >
                        <span style={{ color: "white" }}>
                            CREATE NEW +
                        </span>
                    </Button>
                    : null}
            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="xs"
                sx={{
                    width: 360,
                    height: 668,
                    borderRadius: '4px 0px 0px 0px',
                }}>
                <IconButton onClick={() => setOpen(false)} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h3" style={{ marginTop: 60, marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Create Activity
                </Typography>
                <DialogContent style={{ marginBottom: 50, justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ display: 'flex', marginBottom: 15 }}>
                        Choose the type of activity that you would like to create
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                        {activities.map((activity, index) => (
                            <Grid item key={index} xs={6} sx={{ minWidth: '156px' }}>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        textAlign: 'center',
                                        p: '24px 16px',
                                        cursor: 'pointer',
                                        width: '156px',
                                        height: '234px',
                                        borderRadius: '4px 0px 0px 0px',
                                        border: '1px solid',
                                    }}
                                    onClick={() => handleNavigation(activity.path)}
                                >
                                    <img
                                        src={activity.image}
                                        alt={activity.title}
                                        style={{
                                            width: '70px',
                                            height: '70px',
                                            display: 'block',
                                            margin: '10px auto',
                                        }}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {activity.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {activity.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
