import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Add, Delete, CloudUpload } from '@mui/icons-material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ConnectedFocusError } from 'focus-formik-error';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  addCorpJigsawPuzzleAction,
  uploadPuzzleGameImage
} from 'src/store/actions/corpempactivitymanagementAction';

const PuzzleForm = ({ pagedata, setFieldValue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSubmitted = useSelector(
    state => state.corpempactivity.corpjigsawpuzzleCreate.isLoading
  );
  const errorMessage = useSelector(
    state => state.corpempactivity.corpjigsawpuzzleCreate.error
  );
  const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [categories, setCategories] = useState({});
  const levels = [
    { value: '', label: '-- Please Select --' },
    { label: 'Easy(24)', value: '24' },
    { label: 'Intermediate(63)', value: '63' },
    { label: 'Advanced(104)', value: '104' },
    { label: 'Expert(140)', value: '140' },
    { label: 'Master(180)', value: '180' }
  ];
  const Subscriptions = [
    { value: '', label: '-- Please Select --' },
    { label: 'free', value: 'FREE' },
    { label: 'Bronze', value: 'BRONZE' },
    { label: 'Gold', value: 'GOLD' },
    { label: 'Platinum', value: 'PLATINUM' },
    { label: 'diamond', value: 'DIAMOND' }
  ];

  const handleSelectChange = event => {
    const selectedValues =
      typeof event.target.value === 'string'
        ? event.target.value.split(',')
        : event.target.value;
  const updatedLevels = selectedValues
      .map(value => {
        const level = levels.find(l => l.value === value);
        return level ? { [level.label]: value } : null;
      })
      .filter(Boolean); 
    setSelectedLevels(updatedLevels);
  };
  const handleDeleteLevel = levelObj => {
    setSelectedLevels(prev =>
      prev.filter(level => JSON.stringify(level) !== JSON.stringify(levelObj))
    );
  };

  const handleSelectedChange = event => {
    setSelectedSubscriptions(
      typeof event.target.value === 'string'
        ? event.target.value.split(',')
        : event.target.value
    );
  };

  const handleDeleted = Level => {
    setSelectedSubscriptions(prev => prev.filter(p => p !== Level));
  };

  const handleAddCategory = () => {
    const newCategory = `Category ${Object.keys(categories).length + 1}`;
    setCategories(prev => ({ ...prev, [newCategory]: [] }));
  };

  const handleCategoryNameChange = (oldName, newName) => {
    if (!newName.trim() || categories[newName]) return;

    setCategories(prev => {
      const updated = { ...prev };
      updated[newName] = updated[oldName];
      delete updated[oldName];
      return updated;
    });
  };

  const handleImageUpload = (event, category) => {
    const files = event.target.files;
    if (!files) return;

    const data = new FormData();
    Array.from(files).forEach(file => data.append('file', file));

    dispatch(
      uploadPuzzleGameImage(data, res => {
        if (res) {
          const imagePaths = res.path ? [res.path] : [];
          setCategories(prev => ({
            ...prev,
            [category]: [...prev[category], ...imagePaths].slice(0, 7)
          }));
        }
      })
    );
  };
  const handleDeleteImage = (category, imgIndex) => {
    setCategories(prev => ({
      ...prev,
      [category]: prev[category].filter((_, index) => index !== imgIndex)
    }));
  };

  const handleDeleteCategory = category => {
    setCategories(prev => {
      const updated = { ...prev };
      delete updated[category];
      return updated;
    });
  };
  const validateCategories = categories => {
    let errors = [];

    Object.entries(categories).forEach(([categoryName, images]) => {
      if (images.length !== 7) {
        errors.push(
          `${categoryName} must have exactly 7 images, but it has ${images.length}`
        );
      }
    });

    return errors;
  };
  const validationErrors = validateCategories(categories);
  const categoryNames = validationErrors.map(error => error.split(' must')[0]);

  return (
    <Formik
      initialValues={{
        Activity_Name: pagedata?.Activity_Name,
        Activity_Type: 'Online',
        Category: pagedata?.Category,
        Description: pagedata?.Description,
        Activity_Image: pagedata?.Activity_Image,
        Status: pagedata?.Status,
        Created_By:loginEmail,
        categories: categories,
        Allowed_Subscriptions: selectedSubscriptions,
        Difficulty_Levels: selectedLevels
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let submitTest = true;
        try {
          values.Allowed_Subscriptions = selectedSubscriptions;
          values.Difficulty_Levels = selectedLevels;
          values.categories = categories;
          values.Category = pagedata.Category;
          values.Activity_Name = pagedata.Activity_Name;
          values.Description = pagedata.Description;
          values.Activity_Image = pagedata.Activity_Image;
          values.Status = pagedata.Status;
          if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(addCorpJigsawPuzzleAction(formValues, navigate));
          }
        } catch (error) {
          console.error('Submission error:', error);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue
      }) => (
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <ConnectedFocusError />
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FormControl sx={{ display: 'flex', minWidth: '328px' }}>
                    <InputLabel id="Available levels of difficulty *">
                      Available levels of difficulty *
                    </InputLabel>
                    <Select
                      labelId="available-levels-label"
                      multiple
                      value={selectedLevels.map(
                        levelObj => Object.values(levelObj)[0]
                      )} 
                      onChange={handleSelectChange}
                      renderValue={selected => (
                        <Box
                          sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                          {selectedLevels.map(levelObj => {
                            const label = Object.keys(levelObj)[0];
                            return (
                              <Chip
                                key={label}
                                label={label}
                                onDelete={() => handleDeleteLevel(levelObj)}
                                variant="outlined"
                                sx={{
                                  borderColor: '#4B49AC',
                                  '& .MuiChip-deleteIcon': { color: '#4B49AC' }
                                }}
                              />
                            );
                          })}
                        </Box>
                      )}
                    >
                      {levels
                        .filter(level => level.value) 
                        .map((level, i) => (
                          <MenuItem key={i} value={level.value}>
                            {level.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl sx={{ display: 'flex', minWidth: '328px' }}>
                    <InputLabel id="Allowed Subscriptions*">
                      Allowed Subscriptions *
                    </InputLabel>
                    <Select
                      labelId="Allowed Subscriptions *"
                      id="Allowed Subscriptions *"
                      multiple
                      value={selectedSubscriptions}
                      onChange={handleSelectedChange}
                      renderValue={selected => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                            border: 'none'
                          }}
                        >
                          {selected.map(value => (
                            <Chip
                              key={value}
                              variant="outlined"
                              sx={{
                                borderColor: '#4B49AC',
                                '& .MuiChip-deleteIcon': {
                                  color: '#4B49AC'
                                }
                              }}
                              onDelete={() => handleDeleted(value)}
                              label={value}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {Subscriptions.map((subscription, i) => (
                        <MenuItem key={i} value={subscription.value}>
                          {subscription.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {Object.keys(categories).map(category => (
                    <Box key={category} sx={{ mb: 4, p: 2 }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                          <TextField
                            variant="standard"
                            label="Category Name"
                            defaultValue={category}
                            error={categoryNames.includes(category)}
                            helperText={
                              categoryNames.includes(category)
                                ? `${category} must have exactly 7 images`
                                : ''
                            }
                            onBlur={e =>
                              handleCategoryNameChange(category, e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => handleDeleteCategory(category)}
                            color="error"
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        {Array.from({ length: 7 }).map((_, index) => (
                          <Grid item key={index} xs={3}>
                            {categories[category][index] ? (
                              <Box
                                sx={{
                                  position: 'relative',
                                  width: '100%',
                                  height: '160px',
                                  '&:hover .delete-icon': { display: 'flex' }
                                }}
                              >
                                <img
                                  src={categories[category][index]}
                                  alt={`Uploaded ${index}`}
                                  width="100%"
                                  height="100%"
                                  style={{
                                    objectFit: 'contain',
                                    borderRadius: '8px'
                                  }}
                                />
                                <IconButton
                                  className="delete-icon"
                                  onClick={() =>
                                    handleDeleteImage(category, index)
                                  }
                                  sx={{
                                    display: 'none',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)'
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </Box>
                            ) : (
                              <label htmlFor={`upload-${category}-${index}`}>
                                <input
                                  type="file"
                                  id={`upload-${category}-${index}`}
                                  hidden
                                  accept="image/*"
                                  onChange={e =>
                                    handleImageUpload(e, category, index)
                                  }
                                />
                                <Box
                                  sx={{
                                    width: '100%',
                                    height: '160px',
                                    border: '2px dashed #42A5F5',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    color: '#4B49AC'
                                  }}
                                >
                                  <CloudUpload sx={{ color: '#4B49AC' }} />
                                  <Typography
                                    textTransform="uppercase"
                                    variant="body2"
                                  >
                                    Upload
                                  </Typography>
                                </Box>
                              </label>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ))}

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    mt={4}
                  >
                    <Button
                      variant="outlined"
                      endIcon={<Add />}
                      onClick={handleAddCategory}
                      sx={{
                        borderColor: '#2196F380',
                        color: '#2196F3',
                        '&:hover': {
                          borderColor: '#2196F3',
                          backgroundColor: '#2196F320'
                        }
                      }}
                    >
                      Add Category
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              p={3}
            >
              <Button
                variant="outlined"
                style={{ border: '1px solid #27b6cc', marginRight: 10 }}
                component={RouterLink}
                to="/app/corpemp-activity-management"
              >
                <span style={{ color: '#27B6CC' }}>CANCEL</span>
              </Button>
              {isSubmitted ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  disabled={categoryNames.length > 0 || isSubmitted}
                  style={{ backgroundColor: '#27B6CC' }}
                  variant="contained"
                >
                  <span style={{ color: 'white' }}>CREATE</span>
                </Button>
              )}
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default PuzzleForm;
