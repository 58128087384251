import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    Button,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    LinearProgress,
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ViewHappinessSurvey = ({ open, onClose, surveyData, currentSection, setCurrentSection }) => {
    if (!surveyData) return null;

    const progressPercentage = surveyData?.Sections?.length > 1 ? ((currentSection + 1) / surveyData?.Sections?.length) * 100 : 100;

    const handleNext = () => {
        if (currentSection < surveyData?.Sections?.length - 1) {
            setCurrentSection((prev) => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (currentSection > 0) {
            setCurrentSection((prev) => prev - 1);
        }
    };

    const handleExit = () => {
        onClose();
    };

    const { Section_Name, Questions } = surveyData?.Sections[currentSection];

    return (
        <Dialog open={open} onClose={onClose}
            sx={{
                "& .MuiDialog-paper": {
                    backgroundColor: "#ffffff",
                    boxShadow: "none",
                    width: "600px",
                    height: "750px",
                    maxWidth: "none",
                    margin: "auto",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(200, 200, 200, 0.5)",
                },
            }}
        >
            <DialogContent sx={{ overflowY: 'auto' }}>
                <Box
                    sx={{
                        backgroundColor: "#4B49AC",
                        color: "white",
                        padding: "40px",
                        borderRadius: "4px",
                        textAlign: 'left',
                        marginBottom: "20px"
                    }}
                >
                    <Typography sx={{ fontWeight: 300, fontSize: '30px', font: 'Satoshi' }}>
                        Happiness survey
                    </Typography>
                    <Typography sx={{ fontWeight: 300, fontSize: '20px', font: 'Satoshi' }}>
                        "Your Voice, Your Happiness: Share Your Feedback"
                    </Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: "#F4F5F9",
                        color: "black",
                        padding: "20px",
                        borderRadius: "4px",
                        textAlign: "left",
                        border: "1px dotted #4B49AC",
                        marginBottom: "20px"
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Typography variant="body1" component="span">
                            Disclaimer
                        </Typography>
                        <img
                            src="/static/elev8-info.png"
                            alt="Add Card"
                            style={{ width: 24, height: 24 }}
                        />
                    </Box>
                    <Typography variant="body1">
                        To ensure authenticity and confidentiality, no personal information is stored. This survey is entirely anonymous, and your responses will only be used to calculate the overall happiness index.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "600px",
                        margin: "0 auto",
                        marginBottom: "20px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            gap: "8px"
                        }}
                    >
                        <LinearProgress
                            variant="determinate"
                            value={progressPercentage}
                            sx={{
                                flex: 1,
                                height: "5px",
                                backgroundColor: "#f0f0f0",
                                "& .MuiLinearProgress-bar": {
                                    backgroundColor: "#4B49AC",
                                },
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: "14px",
                                color: "#4B49AC",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {progressPercentage?.toFixed(0)}%
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="h6" gutterBottom sx={{ color: '#4B49AC' }}>
                    {Section_Name}
                </Typography>
                {Questions.map(({ Question, Question_Id }, index) => (
                    <Box key={Question_Id} mt={2}>
                        <Typography variant="body1">{`${index + 1}. ${Question}`}</Typography>
                        <RadioGroup
                            row
                            style={{
                                justifyContent: "space-between",
                                width: "100%",
                                flexWrap: "nowrap",
                                marginTop: 10,
                                marginBottom: 10
                            }}
                        >
                            {[
                                "Strongly Disagree",
                                "Disagree",
                                "Neutral",
                                "Agree",
                                "Strongly Agree",
                            ].map((option, idx) => (
                                <FormControlLabel
                                    key={idx}
                                    value={option}
                                    control={<Radio disabled />}
                                    label={option}
                                    labelPlacement="top"
                                    style={{
                                        flex: "none",
                                        textAlign: "center",
                                        fontSize: "12px",
                                        whiteSpace: "normal",
                                        wordBreak: "break-word",
                                        margin: "0 8px",
                                    }}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                ))}
            </DialogContent>
            <Box display="flex" justifyContent="space-between" p={2}>
                {surveyData?.Sections?.length > 1 && currentSection !== 0 ? (
                    <Button
                        onClick={handlePrevious}
                        variant="outlined"
                        startIcon={<ArrowBackIosIcon fontSize="small" />}
                    >
                        Previous
                    </Button>
                ) : (
                    <Box width="72px" />
                )}
                <Button
                    onClick={currentSection === surveyData?.Sections?.length - 1 ? handleExit : handleNext}
                    variant={currentSection !== surveyData?.Sections?.length - 1 ? "outlined" : "contained"}
                    color="primary"
                    endIcon={
                        currentSection !== surveyData?.Sections?.length - 1 ? (
                            <ArrowForwardIosIcon fontSize="small" />
                        ) : null
                    }
                >
                    {currentSection === surveyData?.Sections?.length - 1 ? "Exit" : "Next"}
                </Button>
            </Box>
        </Dialog>
    );
};

export default ViewHappinessSurvey;