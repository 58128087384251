import {
    CORPEMP_ACTIVITY_CREATE_REQUEST,
    CORPEMP_ACTIVITY_CREATE_SUCCESS,
    CORPEMP_ACTIVITY_CREATE_FAIL,

    CORPEMP_ACTIVITY_UPDATE_REQUEST,
    CORPEMP_ACTIVITY_UPDATE_SUCCESS,
    CORPEMP_ACTIVITY_UPDATE_FAIL,

    CORPEMP_QUIZ_CREATE_REQUEST,
    CORPEMP_QUIZ_CREATE_SUCCESS,
    CORPEMP_QUIZ_CREATE_FAIL,

    CORPEMP_QUIZ_UPDATE_REQUEST,
    CORPEMP_QUIZ_UPDATE_SUCCESS,
    CORPEMP_QUIZ_UPDATE_FAIL,

    CORP_JIGSAW_PUZZLE_CREATE_REQUEST,
    CORP_JIGSAW_PUZZLE_CREATE_SUCCESS,
    CORP_JIGSAW_PUZZLE_CREATE_FAIL,

    CORP_JIGSAW_PUZZLE_UPDATE_REQUEST,
    CORP_JIGSAW_PUZZLE_UPDATE_SUCCESS,
    CORP_JIGSAW_PUZZLE_UPDATE_FAIL,
} from '../actions/corpempactivitymanagementAction'

const initialState = {
    corpempactivityCreate: { corpempactivity: {}, error: '', isLoading: false },
    corpempactivityUpdate: { corpempactivity: {}, error: '', isLoading: false },
    corpempquizCreate: { corpempquiz: {}, error: '', isLoading: false },
    corpempquizUpdate: { corpempquiz: {}, error: '', isLoading: false },
    corpjigsawpuzzleCreate: { corpjigsawpuzzle: {}, error: '', isLoading: false },
    corpjigsawpuzzleUpdate: { corpjigsawpuzzle: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CORPEMP_ACTIVITY_CREATE_REQUEST:
            return { ...state, corpempactivityCreate: { corpempactivity: {}, error: '', isLoading: true } };
        case CORPEMP_ACTIVITY_CREATE_SUCCESS:
            return { ...state, corpempactivityCreate: { corpempactivity: state.corpempactivityCreate.corpempactivity, error: '', isLoading: false } };
        case CORPEMP_ACTIVITY_CREATE_FAIL:
            return { ...state, corpempactivityCreate: { corpempactivity: {}, error: action.payload, isLoading: false } };

        case CORPEMP_ACTIVITY_UPDATE_REQUEST:
            return { ...state, corpempactivityUpdate: { corpempactivity: {}, error: '', isLoading: true } };
        case CORPEMP_ACTIVITY_UPDATE_SUCCESS:
            return { ...state, corpempactivityUpdate: { corpempactivity: action.payload, error: '', isLoading: false } };
        case CORPEMP_ACTIVITY_UPDATE_FAIL:
            return { ...state, corpempactivityUpdate: { corpempactivity: {}, error: action.payload, isLoading: false } };

        case CORPEMP_QUIZ_CREATE_REQUEST:
            return { ...state, corpempquizCreate: { corpempquiz: {}, error: '', isLoading: true } };
        case CORPEMP_QUIZ_CREATE_SUCCESS:
            return { ...state, corpempquizCreate: { corpempquiz: state.corpempquizCreate.corpempquiz, error: '', isLoading: false } };
        case CORPEMP_QUIZ_CREATE_FAIL:
            return { ...state, corpempquizCreate: { corpempquiz: {}, error: action.payload, isLoading: false } };

        case CORPEMP_QUIZ_UPDATE_REQUEST:
            return { ...state, corpempquizUpdate: { corpempquiz: {}, error: '', isLoading: true } };
        case CORPEMP_QUIZ_UPDATE_SUCCESS:
            return { ...state, corpempquizUpdate: { corpempquiz: action.payload, error: '', isLoading: false } };
        case CORPEMP_QUIZ_UPDATE_FAIL:
            return { ...state, corpempquizUpdate: { corpempquiz: {}, error: action.payload, isLoading: false } };
        
            case CORP_JIGSAW_PUZZLE_CREATE_REQUEST:
              return { ...state, corpjigsawpuzzleCreate: { corpjigsawpuzzle: {}, error: '', isLoading: true } };
          case CORP_JIGSAW_PUZZLE_CREATE_SUCCESS:
              return { ...state, corpjigsawpuzzleCreate: { corpjigsawpuzzle: state.corpjigsawpuzzleCreate.corpjigsawpuzzle, error: '', isLoading: false } };
          case CORP_JIGSAW_PUZZLE_CREATE_FAIL:
              return { ...state, corpjigsawpuzzleCreate: { corpjigsawpuzzle: {}, error: action.payload, isLoading: false } };
  
          case CORP_JIGSAW_PUZZLE_UPDATE_REQUEST:
              return { ...state, corpjigsawpuzzleUpdate: { corpjigsawpuzzle: {}, error: '', isLoading: true } };
          case CORP_JIGSAW_PUZZLE_UPDATE_SUCCESS:
              return { ...state, corpjigsawpuzzleUpdate: { corpjigsawpuzzle: action.payload, error: '', isLoading: false } };
          case CORP_JIGSAW_PUZZLE_UPDATE_FAIL:
              return { ...state, corpjigsawpuzzleUpdate: { corpjigsawpuzzle: {}, error: action.payload, isLoading: false } };
             default:
            return state;
    }
}
