import {
    CORPEMP_HAPPINESS_SURVEY_CREATE_REQUEST,
    CORPEMP_HAPPINESS_SURVEY_CREATE_SUCCESS,
    CORPEMP_HAPPINESS_SURVEY_CREATE_FAIL,

    CORPEMP_HAPPINESS_SURVEY_UPDATE_REQUEST,
    CORPEMP_HAPPINESS_SURVEY_UPDATE_SUCCESS,
    CORPEMP_HAPPINESS_SURVEY_UPDATE_FAIL,
} from '../actions/corpemphappinesssurveyAction'

const initialState = {
    corpemphappinesssurveyCreate: { corpemphappinesssurvey: {}, error: '', isLoading: false },
    corpemphappinesssurveyUpdate: { corpemphappinesssurvey: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CORPEMP_HAPPINESS_SURVEY_CREATE_REQUEST:
            return { ...state, corpemphappinesssurveyCreate: { corpemphappinesssurvey: {}, error: '', isLoading: true } };
        case CORPEMP_HAPPINESS_SURVEY_CREATE_SUCCESS:
            return { ...state, corpemphappinesssurveyCreate: { corpemphappinesssurvey: state.corpemphappinesssurveyCreate.corpemphappinesssurvey, error: '', isLoading: false } };
        case CORPEMP_HAPPINESS_SURVEY_CREATE_FAIL:
            return { ...state, corpemphappinesssurveyCreate: { corpemphappinesssurvey: {}, error: action.payload, isLoading: false } };

        case CORPEMP_HAPPINESS_SURVEY_UPDATE_REQUEST:
            return { ...state, corpemphappinesssurveyUpdate: { corpemphappinesssurvey: {}, error: '', isLoading: true } };
        case CORPEMP_HAPPINESS_SURVEY_UPDATE_SUCCESS:
            return { ...state, corpemphappinesssurveyUpdate: { corpemphappinesssurvey: action.payload, error: '', isLoading: false } };
        case CORPEMP_HAPPINESS_SURVEY_UPDATE_FAIL:
            return { ...state, corpemphappinesssurveyUpdate: { corpemphappinesssurvey: {}, error: action.payload, isLoading: false } };
        default:
            return state;
    }
}